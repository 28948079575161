:root {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --surface-a:#ffffff;
  --surface-b:#f8f9fa;
  --surface-c:#e9ecef;
  --surface-d:#dee2e6;
  --surface-e:#ffffff;
  --surface-f:#ffffff;
  --text-color:#222222;
  --text-color-secondary:#555555;
  --primary-color:#C5357B;
  --primary-color-text:#ffffff;
  --surface-0: #ffffff;
  --surface-50: #FAFAFA;
  --surface-100: #F5F5F5;
  --surface-200: #EEEEEE;
  --surface-300: #E0E0E0;
  --surface-400: #BDBDBD;
  --surface-500: #9E9E9E;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --content-padding:1rem;
  --inline-spacing:0.5rem;
  --border-radius:3px;
  --surface-ground:#f8f9fa;
  --surface-section:#ffffff;
  --surface-card:#ffffff;
  --surface-overlay:#ffffff;
  --surface-border:#dee2e6;
  --surface-hover:#e9ecef;
  --maskbg: rgba(0, 0, 0, 0.4);
  --highlight-bg: #C5357B;
  --highlight-text-color: #ffffff;
  --focus-ring: 0 0 0 0.2rem #bfd1f6;
  color-scheme: light;
}

:root {
  --blue-50:rgb(243.9, 249.75, 254.4);
  --blue-100:rgb(201.72, 229.8, 252.12);
  --blue-200:rgb(159.54, 209.85, 249.84);
  --blue-300:rgb(117.36, 189.9, 247.56);
  --blue-400:rgb(75.18, 169.95, 245.28);
  --blue-500:#2196f3;
  --blue-600:rgb(28.05, 127.5, 206.55);
  --blue-700:rgb(23.1, 105, 170.1);
  --blue-800:rgb(18.15, 82.5, 133.65);
  --blue-900:rgb(13.2, 60, 97.2);
  --green-50:rgb(246.05, 251, 246.25);
  --green-100:rgb(212.04, 235.8, 213);
  --green-200:rgb(178.03, 220.6, 179.75);
  --green-300:rgb(144.02, 205.4, 146.5);
  --green-400:rgb(110.01, 190.2, 113.25);
  --green-500:#4caf50;
  --green-600:rgb(64.6, 148.75, 68);
  --green-700:rgb(53.2, 122.5, 56);
  --green-800:rgb(41.8, 96.25, 44);
  --green-900:rgb(30.4, 70, 32);
  --yellow-50:rgb(254.8, 251.85, 244.5);
  --yellow-100:rgb(254.04, 239.88, 204.6);
  --yellow-200:rgb(253.28, 227.91, 164.7);
  --yellow-300:rgb(252.52, 215.94, 124.8);
  --yellow-400:rgb(251.76, 203.97, 84.9);
  --yellow-500:#fbc02d;
  --yellow-600:rgb(213.35, 163.2, 38.25);
  --yellow-700:rgb(175.7, 134.4, 31.5);
  --yellow-800:rgb(138.05, 105.6, 24.75);
  --yellow-900:rgb(100.4, 76.8, 18);
  --cyan-50:rgb(242.25, 251.65, 252.85);
  --cyan-100:rgb(193.8, 238.92, 244.68);
  --cyan-200:rgb(145.35, 226.19, 236.51);
  --cyan-300:rgb(96.9, 213.46, 228.34);
  --cyan-400:rgb(48.45, 200.73, 220.17);
  --cyan-500:#00bcd4;
  --cyan-600:rgb(0, 159.8, 180.2);
  --cyan-700:rgb(0, 131.6, 148.4);
  --cyan-800:rgb(0, 103.4, 116.6);
  --cyan-900:rgb(0, 75.2, 84.8);
  --pink-50:rgb(253.9, 243.75, 247.2);
  --pink-100:rgb(249.72, 201, 217.56);
  --pink-200:rgb(245.54, 158.25, 187.92);
  --pink-300:rgb(241.36, 115.5, 158.28);
  --pink-400:rgb(237.18, 72.75, 128.64);
  --pink-500:#e91e63;
  --pink-600:rgb(198.05, 25.5, 84.15);
  --pink-700:rgb(163.1, 21, 69.3);
  --pink-800:rgb(128.15, 16.5, 54.45);
  --pink-900:rgb(93.2, 12, 39.6);
  --indigo-50:rgb(245.4, 246.3, 251.3);
  --indigo-100:rgb(208.92, 213.24, 237.24);
  --indigo-200:rgb(172.44, 180.18, 223.18);
  --indigo-300:rgb(135.96, 147.12, 209.12);
  --indigo-400:rgb(99.48, 114.06, 195.06);
  --indigo-500:#3f51b5;
  --indigo-600:rgb(53.55, 68.85, 153.85);
  --indigo-700:rgb(44.1, 56.7, 126.7);
  --indigo-800:rgb(34.65, 44.55, 99.55);
  --indigo-900:rgb(25.2, 32.4, 72.4);
  --teal-50:rgb(242.25, 249.75, 249.05);
  --teal-100:rgb(193.8, 229.8, 226.44);
  --teal-200:rgb(145.35, 209.85, 203.83);
  --teal-300:rgb(96.9, 189.9, 181.22);
  --teal-400:rgb(48.45, 169.95, 158.61);
  --teal-500:#009688;
  --teal-600:rgb(0, 127.5, 115.6);
  --teal-700:rgb(0, 105, 95.2);
  --teal-800:rgb(0, 82.5, 74.8);
  --teal-900:rgb(0, 60, 54.4);
  --orange-50:rgb(254.5, 248.45, 242.25);
  --orange-100:rgb(252.6, 223.56, 193.8);
  --orange-200:rgb(250.7, 198.67, 145.35);
  --orange-300:rgb(248.8, 173.78, 96.9);
  --orange-400:rgb(246.9, 148.89, 48.45);
  --orange-500:#f57c00;
  --orange-600:rgb(208.25, 105.4, 0);
  --orange-700:rgb(171.5, 86.8, 0);
  --orange-800:rgb(134.75, 68.2, 0);
  --orange-900:rgb(98, 49.6, 0);
  --bluegray-50:rgb(247.05, 248.5, 249.2);
  --bluegray-100:rgb(216.84, 223.8, 227.16);
  --bluegray-200:rgb(186.63, 199.1, 205.12);
  --bluegray-300:rgb(156.42, 174.4, 183.08);
  --bluegray-400:rgb(126.21, 149.7, 161.04);
  --bluegray-500:#607d8b;
  --bluegray-600:rgb(81.6, 106.25, 118.15);
  --bluegray-700:rgb(67.2, 87.5, 97.3);
  --bluegray-800:rgb(52.8, 68.75, 76.45);
  --bluegray-900:rgb(38.4, 50, 55.6);
  --purple-50:rgb(250.05, 244.2, 251.05);
  --purple-100:rgb(231.24, 203.16, 236.04);
  --purple-200:rgb(212.43, 162.12, 221.03);
  --purple-300:rgb(193.62, 121.08, 206.02);
  --purple-400:rgb(174.81, 80.04, 191.01);
  --purple-500:#9c27b0;
  --purple-600:rgb(132.6, 33.15, 149.6);
  --purple-700:rgb(109.2, 27.3, 123.2);
  --purple-800:rgb(85.8, 21.45, 96.8);
  --purple-900:rgb(62.4, 15.6, 70.4);
  --red-50:rgb(255, 245.45, 244.75);
  --red-100:rgb(255, 209.16, 205.8);
  --red-200:rgb(255, 172.87, 166.85);
  --red-300:rgb(255, 136.58, 127.9);
  --red-400:rgb(255, 100.29, 88.95);
  --red-500:#ff4032;
  --red-600:rgb(216.75, 54.4, 42.5);
  --red-700:rgb(178.5, 44.8, 35);
  --red-800:rgb(140.25, 35.2, 27.5);
  --red-900:rgb(102, 25.6, 20);
  --primary-50:rgb(252.1, 244.9, 248.4);
  --primary-100:rgb(241.08, 206.52, 223.32);
  --primary-200:rgb(230.06, 168.14, 198.24);
  --primary-300:rgb(219.04, 129.76, 173.16);
  --primary-400:rgb(208.02, 91.38, 148.08);
  --primary-500:#c5357b;
  --primary-600:rgb(167.45, 45.05, 104.55);
  --primary-700:rgb(137.9, 37.1, 86.1);
  --primary-800:rgb(108.35, 29.15, 67.65);
  --primary-900:rgb(78.8, 21.2, 49.2);
}

.p-editor-container .p-editor-toolbar {
  background: #f8f9fa;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #dee2e6;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 0.5rem 0;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: #495057;
  background: #e9ecef;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
  padding: 0.5rem 1rem;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #dee2e6;
}
.p-editor-container .p-editor-content .ql-editor {
  background: #ffffff;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #C5357B;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #C5357B;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #C5357B;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #C5357B;
}

@layer primeng {
  * {
    box-sizing: border-box;
  }
  .p-component {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    font-weight: normal;
  }
  .p-component-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    transition-duration: 0.2s;
  }
  .p-disabled, .p-component:disabled {
    opacity: 0.8;
  }
  .p-error {
    color: #e4677e;
  }
  .p-text-secondary {
    color: #6c757d;
  }
  .pi {
    font-size: 1rem;
  }
  .p-icon {
    width: 1rem;
    height: 1rem;
  }
  .p-link {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    border-radius: 3px;
  }
  .p-link:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-component-overlay-enter {
    animation: p-component-overlay-enter-animation 150ms forwards;
  }
  .p-component-overlay-leave {
    animation: p-component-overlay-leave-animation 150ms forwards;
  }
  @keyframes p-component-overlay-enter-animation {
    from {
      background-color: transparent;
    }
    to {
      background-color: var(--maskbg);
    }
  }
  @keyframes p-component-overlay-leave-animation {
    from {
      background-color: var(--maskbg);
    }
    to {
      background-color: transparent;
    }
  }
  .p-autocomplete .p-autocomplete-loader {
    right: 0.5rem;
  }
  .p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
    right: 2.857rem;
  }
  .p-autocomplete:not(.p-disabled):hover .p-autocomplete-multiple-container {
    border-color: #C5357B;
  }
  .p-autocomplete:not(.p-disabled).p-focus .p-autocomplete-multiple-container {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
    border-color: #C5357B;
  }
  .p-autocomplete .p-autocomplete-multiple-container {
    padding: 0.25rem 0.5rem;
    gap: 0.5rem;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: 0.25rem 0;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    color: #495057;
    padding: 0;
    margin: 0;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    padding: 0.25rem 0.5rem;
    background: #dee2e6;
    color: #495057;
    border-radius: 16px;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
    margin-left: 0.5rem;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token.p-focus {
    background: #dee2e6;
    color: #495057;
  }
  .p-autocomplete.p-invalid.p-component > .p-inputtext {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  .p-autocomplete-panel {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-autocomplete-panel .p-autocomplete-items {
    padding: 0.5rem 0;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:first-child {
    margin-top: 0;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    color: #ffffff;
    background: #C5357B;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight.p-focus {
    background: rgba(197, 53, 123, 0.24);
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #495057;
    background: #f8f9fa;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:not(.p-highlight):not(.p-disabled):hover {
    color: #495057;
    background: #e9ecef;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #495057;
    background: #ffffff;
    font-weight: 600;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
    padding: 0.5rem 1rem;
    color: #495057;
    background: transparent;
  }
  p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  p-autocomplete.p-autocomplete-clearable .p-inputtext {
    padding-right: 2rem;
  }
  p-autocomplete.p-autocomplete-clearable .p-autocomplete-clear-icon {
    color: #495057;
    right: 0.5rem;
  }
  p-autocomplete.p-autocomplete-clearable .p-autocomplete-dd .p-autocomplete-clear-icon {
    color: #495057;
    right: 2.857rem;
  }
  p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  .p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
    border-color: #C5357B;
  }
  .p-datepicker {
    padding: 0.5rem;
    background: #ffffff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 3px;
  }
  .p-datepicker:not(.p-datepicker-inline) {
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: #ffffff;
  }
  .p-datepicker .p-datepicker-header {
    padding: 0.5rem;
    color: #495057;
    background: #ffffff;
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid #dee2e6;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev,
  .p-datepicker .p-datepicker-header .p-datepicker-next {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
  .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev:focus-visible,
  .p-datepicker .p-datepicker-header .p-datepicker-next:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 2rem;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    color: #495057;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    font-weight: 600;
    padding: 0.5rem;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
    color: #C5357B;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: 0.5rem;
  }
  .p-datepicker table {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
  .p-datepicker table th {
    padding: 0.5rem;
  }
  .p-datepicker table th > span {
    width: 2.5rem;
    height: 2.5rem;
  }
  .p-datepicker table td {
    padding: 0.5rem;
  }
  .p-datepicker table td > span {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border: 1px solid transparent;
  }
  .p-datepicker table td > span.p-highlight {
    color: #ffffff;
    background: #C5357B;
  }
  .p-datepicker table td > span:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-datepicker table td.p-datepicker-today > span {
    background: #ced4da;
    color: #495057;
    border-color: transparent;
  }
  .p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: #ffffff;
    background: #C5357B;
  }
  .p-datepicker .p-datepicker-buttonbar {
    padding: 1rem 0;
    border-top: 1px solid #dee2e6;
  }
  .p-datepicker .p-datepicker-buttonbar .p-button {
    width: auto;
  }
  .p-datepicker .p-timepicker {
    border-top: 1px solid #dee2e6;
    padding: 0.5rem;
  }
  .p-datepicker .p-timepicker button {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-datepicker .p-timepicker button:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
  }
  .p-datepicker .p-timepicker button:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-datepicker .p-timepicker button:last-child {
    margin-top: 0.2em;
  }
  .p-datepicker .p-timepicker span {
    font-size: 1.286rem;
  }
  .p-datepicker .p-timepicker > div {
    padding: 0 0.429rem;
  }
  .p-datepicker.p-datepicker-timeonly .p-timepicker {
    border-top: 0 none;
  }
  .p-datepicker .p-monthpicker {
    margin: 0.5rem 0;
  }
  .p-datepicker .p-monthpicker .p-monthpicker-month {
    padding: 0.5rem;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: #ffffff;
    background: #C5357B;
  }
  .p-datepicker .p-yearpicker {
    margin: 0.5rem 0;
  }
  .p-datepicker .p-yearpicker .p-yearpicker-year {
    padding: 0.5rem;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    color: #ffffff;
    background: #C5357B;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-left: 1px solid #dee2e6;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
    padding-left: 0;
    border-left: 0 none;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
    padding-right: 0;
  }
  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: #e9ecef;
  }
  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
  }
  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
  }
  .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  p-calendar.p-calendar-clearable .p-inputtext {
    padding-right: 2rem;
  }
  p-calendar.p-calendar-clearable .p-calendar-clear-icon {
    color: #495057;
    right: 0.5rem;
  }
  p-calendar.p-calendar-clearable .p-calendar-w-btn .p-calendar-clear-icon {
    color: #495057;
    right: 2.857rem;
  }
  @media screen and (max-width: 769px) {
    .p-datepicker table th, .p-datepicker table td {
      padding: 0;
    }
  }
  .p-cascadeselect {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-cascadeselect:not(.p-disabled):hover {
    border-color: #C5357B;
  }
  .p-cascadeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
    border-color: #C5357B;
  }
  .p-cascadeselect .p-cascadeselect-label {
    background: transparent;
    border: 0 none;
    padding: 0.5rem 0.5rem;
  }
  .p-cascadeselect .p-cascadeselect-label.p-placeholder {
    color: #6c757d;
  }
  .p-cascadeselect .p-cascadeselect-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
  }
  .p-cascadeselect .p-cascadeselect-trigger {
    background: transparent;
    color: #495057;
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-cascadeselect.p-invalid.p-component {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  .p-cascadeselect.p-variant-filled {
    background-color: #f8f9fa;
  }
  .p-cascadeselect.p-variant-filled:enabled:hover {
    background-color: #f8f9fa;
  }
  .p-cascadeselect.p-variant-filled:enabled:focus {
    background-color: #f8f9fa;
  }
  .p-cascadeselect-panel {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-cascadeselect-panel .p-cascadeselect-items {
    padding: 0.5rem 0;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
    margin: 0;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:first-child {
    margin-top: 0;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
    color: #ffffff;
    background: #C5357B;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight.p-focus {
    background: rgba(197, 53, 123, 0.24);
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #495057;
    background: #f8f9fa;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #495057;
    background: #e9ecef;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
    padding: 0.5rem 1rem;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
    font-size: 0.875rem;
  }
  .p-input-filled .p-cascadeselect {
    background: #f8f9fa;
  }
  .p-input-filled .p-cascadeselect:not(.p-disabled):hover {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
    background-color: #f8f9fa;
  }
  p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-label {
    padding-right: 0.5rem;
  }
  p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-clear-icon {
    color: #495057;
    right: 2.357rem;
  }
  .p-overlay-modal .p-cascadeselect-sublist .p-cascadeselect-panel {
    box-shadow: none;
    border-radius: 0;
    padding: 0.25rem 0 0.25rem 0.5rem;
  }
  .p-overlay-modal .p-cascadeselect-item-active > .p-cascadeselect-item-content .p-cascadeselect-group-icon {
    transform: rotate(90deg);
  }
  .p-checkbox {
    width: 20px;
    height: 20px;
  }
  .p-checkbox .p-checkbox-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 20px;
    height: 20px;
    color: #495057;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    outline-color: transparent;
  }
  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.2s;
    color: #ffffff;
    font-size: 14px;
  }
  .p-checkbox .p-checkbox-box .p-icon {
    width: 14px;
    height: 14px;
  }
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #C5357B;
    background: #C5357B;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #C5357B;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
    border-color: #C5357B;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #571836;
    background: #571836;
    color: #ffffff;
  }
  .p-checkbox.p-variant-filled .p-checkbox-box {
    background-color: #f8f9fa;
  }
  .p-checkbox.p-variant-filled .p-checkbox-box.p-highlight {
    background: #C5357B;
  }
  .p-checkbox.p-variant-filled:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: #f8f9fa;
  }
  .p-checkbox.p-variant-filled:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: #571836;
  }
  p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  .p-input-filled .p-checkbox .p-checkbox-box {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: #C5357B;
  }
  .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: #571836;
  }
  .p-checkbox-label {
    margin-left: 0.5rem;
  }
  .p-highlight .p-checkbox .p-checkbox-box {
    border-color: #ffffff;
  }
  p-tristatecheckbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  .p-chips:not(.p-disabled):hover .p-chips-multiple-container {
    border-color: #C5357B;
  }
  .p-chips:not(.p-disabled).p-focus .p-chips-multiple-container {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
    border-color: #C5357B;
  }
  .p-chips .p-chips-multiple-container {
    padding: 0.25rem 0.5rem;
    gap: 0.5rem;
  }
  .p-chips .p-chips-multiple-container .p-chips-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: #dee2e6;
    color: #495057;
    border-radius: 16px;
  }
  .p-chips .p-chips-multiple-container .p-chips-token.p-focus {
    background: #dee2e6;
    color: #495057;
  }
  .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
    margin-left: 0.5rem;
  }
  .p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: 0.25rem 0;
  }
  .p-chips .p-chips-multiple-container .p-chips-input-token input {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    color: #495057;
    padding: 0;
    margin: 0;
  }
  p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  p-chips.p-chips-clearable .p-inputtext {
    padding-right: 1.5rem;
  }
  p-chips.p-chips-clearable .p-chips-clear-icon {
    color: #495057;
    right: 0.5rem;
  }
  .p-colorpicker-preview,
  .p-fluid .p-colorpicker-preview.p-inputtext {
    width: 2rem;
    height: 2rem;
  }
  .p-colorpicker-panel {
    background: #323232;
    border: 1px solid #191919;
  }
  .p-colorpicker-panel .p-colorpicker-color-handle,
  .p-colorpicker-panel .p-colorpicker-hue-handle {
    border-color: #ffffff;
  }
  .p-colorpicker-overlay-panel {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-dropdown {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-dropdown:not(.p-disabled):hover {
    border-color: #C5357B;
  }
  .p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
    border-color: #C5357B;
  }
  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.5rem;
  }
  .p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
  }
  .p-dropdown .p-dropdown-label.p-placeholder {
    color: #6c757d;
  }
  .p-dropdown .p-dropdown-label:focus, .p-dropdown .p-dropdown-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
  }
  .p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: #495057;
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-dropdown .p-dropdown-clear-icon {
    color: #495057;
    right: 2.357rem;
  }
  .p-dropdown.p-invalid.p-component {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  .p-dropdown.p-variant-filled {
    background-color: #f8f9fa;
  }
  .p-dropdown.p-variant-filled:enabled:hover {
    background-color: #f8f9fa;
  }
  .p-dropdown.p-variant-filled:enabled:focus {
    background-color: #f8f9fa;
  }
  .p-dropdown-panel {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-dropdown-panel .p-dropdown-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: #495057;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.5rem;
    margin-right: -1.5rem;
  }
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    right: 0.5rem;
    color: #495057;
  }
  .p-dropdown-panel .p-dropdown-items {
    padding: 0.5rem 0;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:first-child {
    margin-top: 0;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #ffffff;
    background: #C5357B;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
    background: rgba(197, 53, 123, 0.24);
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #495057;
    background: #f8f9fa;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #495057;
    background: #e9ecef;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #495057;
    background: #ffffff;
    font-weight: 600;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    padding: 0.5rem 1rem;
    color: #495057;
    background: transparent;
  }
  .p-input-filled .p-dropdown {
    background: #f8f9fa;
  }
  .p-input-filled .p-dropdown:not(.p-disabled):hover {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-dropdown:not(.p-disabled).p-focus {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
    background-color: transparent;
  }
  p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  .p-icon-field .p-input-icon {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
  }
  .p-inputgroup-addon {
    background: #e9ecef;
    color: #6c757d;
    border-top: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    padding: 0.5rem 0.5rem;
    min-width: 2.357rem;
  }
  .p-inputgroup-addon:last-child {
    border-right: 1px solid #ced4da;
  }
  .p-inputgroup > .p-component,
  .p-inputgroup > .p-inputwrapper > .p-inputtext,
  .p-inputgroup > .p-float-label > .p-component {
    border-radius: 0;
    margin: 0;
  }
  .p-inputgroup > .p-component + .p-inputgroup-addon,
  .p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon,
  .p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
    border-left: 0 none;
  }
  .p-inputgroup > .p-component:focus,
  .p-inputgroup > .p-inputwrapper > .p-inputtext:focus,
  .p-inputgroup > .p-float-label > .p-component:focus {
    z-index: 1;
  }
  .p-inputgroup > .p-component:focus ~ label,
  .p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label,
  .p-inputgroup > .p-float-label > .p-component:focus ~ label {
    z-index: 1;
  }
  .p-inputgroup-addon:first-child,
  .p-inputgroup button:first-child,
  .p-inputgroup input:first-child,
  .p-inputgroup > .p-inputwrapper:first-child > .p-component,
  .p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-inputgroup .p-float-label:first-child input {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-inputgroup-addon:last-child,
  .p-inputgroup button:last-child,
  .p-inputgroup input:last-child,
  .p-inputgroup > .p-inputwrapper:last-child > .p-component,
  .p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-inputgroup .p-float-label:last-child input {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-fluid .p-inputgroup .p-button {
    width: auto;
  }
  .p-fluid .p-inputgroup .p-button.p-button-icon-only {
    width: 2.357rem;
  }
  .p-icon-field-left .p-input-icon:first-of-type {
    left: 0.5rem;
    color: #495057;
  }
  .p-icon-field-right .p-input-icon:last-of-type {
    right: 0.5rem;
    color: #495057;
  }
  p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  p-inputmask.p-inputmask-clearable .p-inputtext {
    padding-right: 2rem;
  }
  p-inputmask.p-inputmask-clearable .p-inputmask-clear-icon {
    color: #495057;
    right: 0.5rem;
  }
  .p-inputmask.p-variant-filled {
    background-color: #f8f9fa;
  }
  .p-inputmask.p-variant-filled:enabled:hover {
    background-color: #f8f9fa;
  }
  .p-inputmask.p-variant-filled:enabled:focus {
    background-color: #f8f9fa;
  }
  p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  p-inputnumber.p-inputnumber-clearable .p-inputnumber-input {
    padding-right: 2rem;
  }
  p-inputnumber.p-inputnumber-clearable .p-inputnumber-clear-icon {
    color: #495057;
    right: 0.5rem;
  }
  p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-stacked .p-inputnumber-clear-icon {
    right: 2.857rem;
  }
  p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-horizontal .p-inputnumber-clear-icon {
    right: 2.857rem;
  }
  p-inputnumber.p-inputnumber.p-variant-filled > .p-inputnumber-input {
    background-color: #f8f9fa;
  }
  p-inputnumber.p-inputnumber.p-variant-filled > .p-inputnumber-input:enabled:hover {
    background-color: #f8f9fa;
  }
  p-inputnumber.p-inputnumber.p-variant-filled > .p-inputnumber-input:enabled:focus {
    background-color: #f8f9fa;
  }
  .p-inputotp {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .p-inputotp-input {
    text-align: center;
    width: 2rem;
  }
  .p-inputswitch {
    width: 3rem;
    height: 1.75rem;
  }
  .p-inputswitch .p-inputswitch-slider {
    background: #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 30px;
  }
  .p-inputswitch .p-inputswitch-slider:before {
    background: #ffffff;
    width: 1.25rem;
    height: 1.25rem;
    left: 0.25rem;
    margin-top: -0.625rem;
    border-radius: 50%;
    transition-duration: 0.2s;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.25rem);
  }
  .p-inputswitch.p-focus .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: #c3cad2;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #C5357B;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #ffffff;
  }
  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: #9b2a61;
  }
  p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch > .p-inputswitch-slider {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  .p-inputtext {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.5rem 0.5rem;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 3px;
  }
  .p-inputtext:enabled:hover {
    border-color: #C5357B;
  }
  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
    border-color: #C5357B;
  }
  .p-inputtext.ng-dirty.ng-invalid {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  .p-inputtext.p-variant-filled {
    background-color: #f8f9fa;
  }
  .p-inputtext.p-variant-filled:enabled:hover {
    background-color: #f8f9fa;
  }
  .p-inputtext.p-variant-filled:enabled:focus {
    background-color: #f8f9fa;
  }
  .p-inputtext.p-inputtext-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.4375rem;
  }
  .p-inputtext.p-inputtext-lg {
    font-size: 1.25rem;
    padding: 0.625rem 0.625rem;
  }
  .p-float-label > label {
    left: 0.5rem;
    color: #6c757d;
    transition-duration: 0.2s;
  }
  .p-float-label > .ng-invalid.ng-dirty + label {
    color: #ced4da #ced4da #ced4da #e4677e;
  }
  .p-input-icon-left > .p-icon-wrapper.p-icon,
  .p-input-icon-left > i:first-of-type {
    left: 0.5rem;
    color: #495057;
  }
  .p-input-icon-left > .p-inputtext {
    padding-left: 2rem;
  }
  .p-input-icon-left.p-float-label > label {
    left: 2rem;
  }
  .p-input-icon-right > .p-icon-wrapper,
  .p-input-icon-right > i:last-of-type {
    right: 0.5rem;
    color: #495057;
  }
  .p-input-icon-right > .p-inputtext {
    padding-right: 2rem;
  }
  .p-icon-field-left > .p-inputtext {
    padding-left: 2rem;
  }
  .p-icon-field-left.p-float-label > label {
    left: 2rem;
  }
  .p-icon-field-right > .p-inputtext {
    padding-right: 2rem;
  }
  ::-webkit-input-placeholder {
    color: #6c757d;
  }
  :-moz-placeholder {
    color: #6c757d;
  }
  ::-moz-placeholder {
    color: #6c757d;
  }
  :-ms-input-placeholder {
    color: #6c757d;
  }
  .p-input-filled .p-inputtext {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-inputtext:enabled:hover {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-inputtext:enabled:focus {
    background-color: #f8f9fa;
  }
  .p-inputtext-sm .p-inputtext {
    font-size: 0.875rem;
    padding: 0.4375rem 0.4375rem;
  }
  .p-inputtext-lg .p-inputtext {
    font-size: 1.25rem;
    padding: 0.625rem 0.625rem;
  }
  .p-listbox {
    background: #ffffff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-listbox .p-listbox-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: #495057;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-listbox .p-listbox-header .p-listbox-filter {
    padding-right: 1.5rem;
  }
  .p-listbox .p-listbox-header .p-listbox-filter-icon {
    right: 0.5rem;
    color: #495057;
  }
  .p-listbox .p-listbox-header .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-listbox .p-listbox-list {
    padding: 0.5rem 0;
    outline: 0 none;
  }
  .p-listbox .p-listbox-list .p-listbox-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: #495057;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-listbox .p-listbox-list .p-listbox-item:first-child {
    margin-top: 0;
  }
  .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: #ffffff;
    background: #C5357B;
  }
  .p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-listbox .p-listbox-list .p-listbox-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #495057;
    background: #ffffff;
    font-weight: 600;
  }
  .p-listbox .p-listbox-list .p-listbox-empty-message {
    padding: 0.5rem 1rem;
    color: #495057;
    background: transparent;
  }
  .p-listbox:not(.p-disabled) .p-listbox-item.p-highlight.p-focus {
    background: rgba(197, 53, 123, 0.24);
  }
  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #495057;
    background: #e9ecef;
  }
  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: #495057;
    background: #e9ecef;
  }
  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover.p-focus {
    color: #495057;
    background: #e9ecef;
  }
  .p-listbox.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
    border-color: #C5357B;
  }
  p-listbox.ng-dirty.ng-invalid > .p-listbox {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  .p-multiselect {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-multiselect:not(.p-disabled):hover {
    border-color: #C5357B;
  }
  .p-multiselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
    border-color: #C5357B;
  }
  .p-multiselect .p-multiselect-label {
    padding: 0.5rem 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-multiselect .p-multiselect-label.p-placeholder {
    color: #6c757d;
  }
  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: #dee2e6;
    color: #495057;
    border-radius: 16px;
  }
  .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
    margin-left: 0.5rem;
  }
  .p-multiselect .p-multiselect-trigger {
    background: transparent;
    color: #495057;
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-multiselect.p-variant-filled {
    background: #f8f9fa;
  }
  .p-multiselect.p-variant-filled:not(.p-disabled):hover {
    background-color: #f8f9fa;
  }
  .p-multiselect.p-variant-filled:not(.p-disabled).p-focus {
    background-color: #f8f9fa;
  }
  .p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: 0.25rem 0.5rem;
  }
  .p-multiselect-clearable .p-multiselect-label-container {
    padding-right: 1.5rem;
  }
  .p-multiselect-clearable .p-multiselect-clear-icon {
    color: #495057;
    right: 2.357rem;
  }
  .p-multiselect-panel {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-multiselect-panel .p-multiselect-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: #495057;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding-right: 1.5rem;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    right: 0.5rem;
    color: #495057;
  }
  .p-multiselect-panel .p-multiselect-header .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    margin-left: 0.5rem;
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-multiselect-panel .p-multiselect-items {
    padding: 0.5rem 0;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:first-child {
    margin-top: 0;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #ffffff;
    background: #C5357B;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight.p-focus {
    background: rgba(197, 53, 123, 0.24);
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #495057;
    background: #e9ecef;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #495057;
    background: #e9ecef;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #495057;
    background: #ffffff;
    font-weight: 600;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    padding: 0.5rem 1rem;
    color: #495057;
    background: transparent;
  }
  .p-input-filled .p-multiselect {
    background: #f8f9fa;
  }
  .p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-multiselect:not(.p-disabled).p-focus {
    background-color: #f8f9fa;
  }
  p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  .p-password-panel {
    padding: 1rem;
    background: #ffffff;
    color: #495057;
    border: 0 none;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
  .p-password-panel .p-password-meter {
    margin-bottom: 0.5rem;
    background: #dee2e6;
  }
  .p-password-panel .p-password-meter .p-password-strength.weak {
    background: #E53935;
  }
  .p-password-panel .p-password-meter .p-password-strength.medium {
    background: #FFB300;
  }
  .p-password-panel .p-password-meter .p-password-strength.strong {
    background: #43A047;
  }
  p-password.p-password-clearable .p-password-input {
    padding-right: 2rem;
  }
  p-password.p-password-clearable .p-password-clear-icon {
    color: #495057;
    right: 0.5rem;
  }
  p-password.p-password-clearable.p-password-mask .p-password-input {
    padding-right: 3.5rem;
  }
  p-password.p-password-clearable.p-password-mask .p-password-clear-icon {
    color: #495057;
    right: 2rem;
  }
  .p-radiobutton {
    width: 20px;
    height: 20px;
  }
  .p-radiobutton .p-radiobutton-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 20px;
    height: 20px;
    color: #495057;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    outline-color: transparent;
  }
  .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #C5357B;
  }
  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
    border-color: #C5357B;
  }
  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 12px;
    height: 12px;
    transition-duration: 0.2s;
    background-color: #ffffff;
  }
  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #C5357B;
    background: #C5357B;
  }
  .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: #571836;
    background: #571836;
    color: #ffffff;
  }
  .p-radiobutton.p-variant-filled .p-radiobutton-box {
    background-color: #f8f9fa;
  }
  .p-radiobutton.p-variant-filled .p-radiobutton-box:not(.p-disabled):hover {
    background-color: #f8f9fa;
  }
  .p-radiobutton.p-variant-filled .p-radiobutton-box.p-highlight {
    background: #C5357B;
  }
  .p-radiobutton.p-variant-filled .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: #571836;
  }
  p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  .p-input-filled .p-radiobutton .p-radiobutton-box {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
    background: #C5357B;
  }
  .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: #571836;
  }
  .p-radiobutton-label {
    margin-left: 0.5rem;
  }
  .p-highlight .p-radiobutton .p-radiobutton-box {
    border-color: #ffffff;
  }
  .p-rating {
    gap: 0.5rem;
  }
  .p-rating .p-rating-item {
    border-radius: 50%;
    outline-color: transparent;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .p-rating .p-rating-item .p-rating-icon {
    color: #495057;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    font-size: 1.143rem;
  }
  .p-rating .p-rating-item .p-rating-icon.p-icon {
    width: 1.143rem;
    height: 1.143rem;
  }
  .p-rating .p-rating-item .p-rating-icon.p-rating-cancel {
    color: #e74c3c;
  }
  .p-rating .p-rating-item.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
    color: #C5357B;
  }
  .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon {
    color: #C5357B;
  }
  .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon.p-rating-cancel {
    color: #c0392b;
  }
  .p-highlight .p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
    color: #ffffff;
  }
  .p-selectbutton .p-button {
    background: #ffffff;
    border: 1px solid #ced4da;
    color: #495057;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-selectbutton .p-button .p-button-icon-left,
  .p-selectbutton .p-button .p-button-icon-right {
    color: #6c757d;
  }
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
    border-color: #ced4da;
    color: #495057;
  }
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #6c757d;
  }
  .p-selectbutton .p-button.p-highlight {
    background: #C5357B;
    border-color: #C5357B;
    color: #ffffff;
  }
  .p-selectbutton .p-button.p-highlight .p-button-icon-left,
  .p-selectbutton .p-button.p-highlight .p-button-icon-right {
    color: #ffffff;
  }
  .p-selectbutton .p-button.p-highlight:hover {
    background: #9b2a61;
    border-color: #9b2a61;
    color: #ffffff;
  }
  .p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
  .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
    color: #ffffff;
  }
  p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  .p-slider {
    background: #dee2e6;
    border: 0 none;
    border-radius: 3px;
  }
  .p-slider.p-slider-horizontal {
    height: 0.286rem;
  }
  .p-slider.p-slider-horizontal .p-slider-handle {
    margin-top: -0.5715rem;
    margin-left: -0.5715rem;
  }
  .p-slider.p-slider-vertical {
    height: 100%;
    width: 0.286rem;
  }
  .p-slider.p-slider-vertical .p-slider-handle {
    height: 1.143rem;
    width: 1.143rem;
    margin-left: -0.5715rem;
    margin-bottom: -0.5715rem;
  }
  .p-slider .p-slider-handle {
    height: 1.143rem;
    width: 1.143rem;
    background: #ffffff;
    border: 2px solid #C5357B;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-slider .p-slider-handle:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-slider .p-slider-range {
    background: #C5357B;
  }
  .p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: #C5357B;
    border-color: #C5357B;
  }
  .p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, left 0.2s;
  }
  .p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
    transition: width 0.2s;
  }
  .p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, bottom 0.2s;
  }
  .p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
    transition: height 0.2s;
  }
  .p-togglebutton.p-button {
    background: #ffffff;
    border: 1px solid #ced4da;
    color: #495057;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-togglebutton.p-button .p-button-icon-left,
  .p-togglebutton.p-button .p-button-icon-right {
    color: #6c757d;
  }
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
    border-color: #ced4da;
    color: #495057;
  }
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #6c757d;
  }
  .p-togglebutton.p-button.p-highlight {
    background: #C5357B;
    border-color: #C5357B;
    color: #ffffff;
  }
  .p-togglebutton.p-button.p-highlight .p-button-icon-left,
  .p-togglebutton.p-button.p-highlight .p-button-icon-right {
    color: #ffffff;
  }
  .p-togglebutton.p-button.p-highlight:hover {
    background: #9b2a61;
    border-color: #9b2a61;
    color: #ffffff;
  }
  .p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
  .p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
    color: #ffffff;
  }
  p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  .p-treeselect {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-treeselect:not(.p-disabled):hover {
    border-color: #C5357B;
  }
  .p-treeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
    border-color: #C5357B;
  }
  .p-treeselect .p-treeselect-label {
    padding: 0.5rem 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-treeselect .p-treeselect-label.p-placeholder {
    color: #6c757d;
  }
  .p-treeselect.p-treeselect-chip .p-treeselect-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: #dee2e6;
    color: #495057;
    border-radius: 16px;
  }
  .p-treeselect .p-treeselect-trigger {
    background: transparent;
    color: #495057;
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-treeselect.p-variant-filled {
    background-color: #f8f9fa;
  }
  .p-treeselect.p-variant-filled:enabled:hover {
    background-color: #f8f9fa;
  }
  .p-treeselect.p-variant-filled:enabled:focus {
    background-color: #f8f9fa;
  }
  p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
    border-color: #ced4da #ced4da #ced4da #e4677e;
  }
  .p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
    padding: 0.25rem 0.5rem;
  }
  .p-treeselect-panel {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-treeselect-panel .p-treeselect-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: #495057;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
    margin-right: 0.5rem;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
    padding-right: 1.5rem;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
    right: 0.5rem;
    color: #495057;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
    padding-right: 3rem;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
    right: 2rem;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-close {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
    border: 0 none;
  }
  .p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
    padding: 0.5rem 1rem;
    color: #495057;
    background: transparent;
  }
  .p-input-filled .p-treeselect {
    background: #f8f9fa;
  }
  .p-input-filled .p-treeselect:not(.p-disabled):hover {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-treeselect:not(.p-disabled).p-focus {
    background-color: #f8f9fa;
  }
  p-treeselect.p-treeselect-clearable .p-treeselect-label-container {
    padding-right: 1.5rem;
  }
  p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon {
    color: #495057;
    right: 2.357rem;
  }
  .p-button {
    color: #ffffff;
    background: #C5357B;
    border: 1px solid #C5357B;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    outline-color: transparent;
  }
  .p-button:not(:disabled):hover {
    background: #9b2a61;
    color: #ffffff;
    border-color: #9b2a61;
  }
  .p-button:not(:disabled):active {
    background: #571836;
    color: #ffffff;
    border-color: #571836;
  }
  .p-button.p-button-outlined {
    background-color: transparent;
    color: #C5357B;
    border: 1px solid;
  }
  .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(197, 53, 123, 0.04);
    color: #C5357B;
    border: 1px solid;
  }
  .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(197, 53, 123, 0.16);
    color: #C5357B;
    border: 1px solid;
  }
  .p-button.p-button-outlined.p-button-plain {
    color: #6c757d;
    border-color: #6c757d;
  }
  .p-button.p-button-outlined.p-button-plain:not(:disabled):hover {
    background: #e9ecef;
    color: #6c757d;
  }
  .p-button.p-button-outlined.p-button-plain:not(:disabled):active {
    background: #dee2e6;
    color: #6c757d;
  }
  .p-button.p-button-text {
    background-color: transparent;
    color: #C5357B;
    border-color: transparent;
  }
  .p-button.p-button-text:not(:disabled):hover {
    background: rgba(197, 53, 123, 0.04);
    color: #C5357B;
    border-color: transparent;
  }
  .p-button.p-button-text:not(:disabled):active {
    background: rgba(197, 53, 123, 0.16);
    color: #C5357B;
    border-color: transparent;
  }
  .p-button.p-button-text.p-button-plain {
    color: #6c757d;
  }
  .p-button.p-button-text.p-button-plain:not(:disabled):hover {
    background: #e9ecef;
    color: #6c757d;
  }
  .p-button.p-button-text.p-button-plain:not(:disabled):active {
    background: #dee2e6;
    color: #6c757d;
  }
  .p-button:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-button .p-button-label {
    transition-duration: 0.2s;
  }
  .p-button .p-button-icon-left {
    margin-right: 0.5rem;
  }
  .p-button .p-button-icon-right {
    margin-left: 0.5rem;
  }
  .p-button .p-button-icon-bottom {
    margin-top: 0.5rem;
  }
  .p-button .p-button-icon-top {
    margin-bottom: 0.5rem;
  }
  .p-button .p-badge {
    margin-left: 0.5rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: #C5357B;
    background-color: #ffffff;
  }
  .p-button.p-button-raised {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .p-button.p-button-rounded {
    border-radius: 2rem;
  }
  .p-button.p-button-icon-only {
    width: 2.357rem;
    padding: 0.5rem 0;
  }
  .p-button.p-button-icon-only .p-button-icon-left,
  .p-button.p-button-icon-only .p-button-icon-right {
    margin: 0;
  }
  .p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 2.357rem;
  }
  .p-button.p-button-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.875rem;
  }
  .p-button.p-button-sm .p-button-icon {
    font-size: 0.875rem;
  }
  .p-button.p-button-lg {
    font-size: 1.25rem;
    padding: 0.625rem 1.25rem;
  }
  .p-button.p-button-lg .p-button-icon {
    font-size: 1.25rem;
  }
  .p-button.p-button-loading-label-only .p-button-label {
    margin-left: 0.5rem;
  }
  .p-button.p-button-loading-label-only .p-button-loading-icon {
    margin-right: 0;
  }
  .p-fluid .p-button {
    width: 100%;
  }
  .p-fluid .p-button-icon-only {
    width: 2.357rem;
  }
  .p-fluid .p-button-group {
    display: flex;
  }
  .p-fluid .p-button-group .p-button {
    flex: 1;
  }
  .p-button.p-button-secondary, .p-button-group.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
    color: #ffffff;
    background: #607D8B;
    border: 1px solid #607D8B;
  }
  .p-button.p-button-secondary:not(:disabled):hover, .p-button-group.p-button-secondary > .p-button:not(:disabled):hover, .p-splitbutton.p-button-secondary > .p-button:not(:disabled):hover {
    background: #546E7A;
    color: #ffffff;
    border-color: #546E7A;
  }
  .p-button.p-button-secondary:not(:disabled):focus, .p-button-group.p-button-secondary > .p-button:not(:disabled):focus, .p-splitbutton.p-button-secondary > .p-button:not(:disabled):focus {
    box-shadow: 0 0 0 0.2rem #B0BEC5;
  }
  .p-button.p-button-secondary:not(:disabled):active, .p-button-group.p-button-secondary > .p-button:not(:disabled):active, .p-splitbutton.p-button-secondary > .p-button:not(:disabled):active {
    background: #455A64;
    color: #ffffff;
    border-color: #455A64;
  }
  .p-button.p-button-secondary.p-button-outlined, .p-button-group.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
    background-color: transparent;
    color: #607D8B;
    border: 1px solid;
  }
  .p-button.p-button-secondary.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-secondary > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(96, 125, 139, 0.04);
    color: #607D8B;
    border: 1px solid;
  }
  .p-button.p-button-secondary.p-button-outlined:not(:disabled):active, .p-button-group.p-button-secondary > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(96, 125, 139, 0.16);
    color: #607D8B;
    border: 1px solid;
  }
  .p-button.p-button-secondary.p-button-text, .p-button-group.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
    background-color: transparent;
    color: #607D8B;
    border-color: transparent;
  }
  .p-button.p-button-secondary.p-button-text:not(:disabled):hover, .p-button-group.p-button-secondary > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(:disabled):hover {
    background: rgba(96, 125, 139, 0.04);
    border-color: transparent;
    color: #607D8B;
  }
  .p-button.p-button-secondary.p-button-text:not(:disabled):active, .p-button-group.p-button-secondary > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(:disabled):active {
    background: rgba(96, 125, 139, 0.16);
    border-color: transparent;
    color: #607D8B;
  }
  .p-button.p-button-info, .p-button-group.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
    color: #ffffff;
    background: #03A9F4;
    border: 1px solid #03A9F4;
  }
  .p-button.p-button-info:not(:disabled):hover, .p-button-group.p-button-info > .p-button:not(:disabled):hover, .p-splitbutton.p-button-info > .p-button:not(:disabled):hover {
    background: #039BE5;
    color: #ffffff;
    border-color: #039BE5;
  }
  .p-button.p-button-info:not(:disabled):focus, .p-button-group.p-button-info > .p-button:not(:disabled):focus, .p-splitbutton.p-button-info > .p-button:not(:disabled):focus {
    box-shadow: 0 0 0 0.2rem rgb(171.5263157895, 228.3157894737, 253.9736842105);
  }
  .p-button.p-button-info:not(:disabled):active, .p-button-group.p-button-info > .p-button:not(:disabled):active, .p-splitbutton.p-button-info > .p-button:not(:disabled):active {
    background: #0288D1;
    color: #ffffff;
    border-color: #0288D1;
  }
  .p-button.p-button-info.p-button-outlined, .p-button-group.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined {
    background-color: transparent;
    color: #03A9F4;
    border: 1px solid;
  }
  .p-button.p-button-info.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-info > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(3, 169, 244, 0.04);
    color: #03A9F4;
    border: 1px solid;
  }
  .p-button.p-button-info.p-button-outlined:not(:disabled):active, .p-button-group.p-button-info > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(3, 169, 244, 0.16);
    color: #03A9F4;
    border: 1px solid;
  }
  .p-button.p-button-info.p-button-text, .p-button-group.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text {
    background-color: transparent;
    color: #03A9F4;
    border-color: transparent;
  }
  .p-button.p-button-info.p-button-text:not(:disabled):hover, .p-button-group.p-button-info > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-text:not(:disabled):hover {
    background: rgba(3, 169, 244, 0.04);
    border-color: transparent;
    color: #03A9F4;
  }
  .p-button.p-button-info.p-button-text:not(:disabled):active, .p-button-group.p-button-info > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-text:not(:disabled):active {
    background: rgba(3, 169, 244, 0.16);
    border-color: transparent;
    color: #03A9F4;
  }
  .p-button.p-button-success, .p-button-group.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
    color: #ffffff;
    background: #4CAF50;
    border: 1px solid #4CAF50;
  }
  .p-button.p-button-success:not(:disabled):hover, .p-button-group.p-button-success > .p-button:not(:disabled):hover, .p-splitbutton.p-button-success > .p-button:not(:disabled):hover {
    background: #43A047;
    color: #ffffff;
    border-color: #43A047;
  }
  .p-button.p-button-success:not(:disabled):focus, .p-button-group.p-button-success > .p-button:not(:disabled):focus, .p-splitbutton.p-button-success > .p-button:not(:disabled):focus {
    box-shadow: 0 0 0 0.2rem rgb(198.874501992, 230.625498008, 200.1573705179);
  }
  .p-button.p-button-success:not(:disabled):active, .p-button-group.p-button-success > .p-button:not(:disabled):active, .p-splitbutton.p-button-success > .p-button:not(:disabled):active {
    background: #388E3C;
    color: #ffffff;
    border-color: #388E3C;
  }
  .p-button.p-button-success.p-button-outlined, .p-button-group.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
    background-color: transparent;
    color: #4CAF50;
    border: 1px solid;
  }
  .p-button.p-button-success.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-success > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(76, 175, 80, 0.04);
    color: #4CAF50;
    border: 1px solid;
  }
  .p-button.p-button-success.p-button-outlined:not(:disabled):active, .p-button-group.p-button-success > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(76, 175, 80, 0.16);
    color: #4CAF50;
    border: 1px solid;
  }
  .p-button.p-button-success.p-button-text, .p-button-group.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
    background-color: transparent;
    color: #4CAF50;
    border-color: transparent;
  }
  .p-button.p-button-success.p-button-text:not(:disabled):hover, .p-button-group.p-button-success > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-text:not(:disabled):hover {
    background: rgba(76, 175, 80, 0.04);
    border-color: transparent;
    color: #4CAF50;
  }
  .p-button.p-button-success.p-button-text:not(:disabled):active, .p-button-group.p-button-success > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-text:not(:disabled):active {
    background: rgba(76, 175, 80, 0.16);
    border-color: transparent;
    color: #4CAF50;
  }
  .p-button.p-button-warning, .p-button-group.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
    color: #495057;
    background: #FFC107;
    border: 1px solid #FFC107;
  }
  .p-button.p-button-warning:not(:disabled):hover, .p-button-group.p-button-warning > .p-button:not(:disabled):hover, .p-splitbutton.p-button-warning > .p-button:not(:disabled):hover {
    background: #FFB300;
    color: #495057;
    border-color: #FFB300;
  }
  .p-button.p-button-warning:not(:disabled):focus, .p-button-group.p-button-warning > .p-button:not(:disabled):focus, .p-splitbutton.p-button-warning > .p-button:not(:disabled):focus {
    box-shadow: 0 0 0 0.2rem rgb(255, 237.625, 185.5);
  }
  .p-button.p-button-warning:not(:disabled):active, .p-button-group.p-button-warning > .p-button:not(:disabled):active, .p-splitbutton.p-button-warning > .p-button:not(:disabled):active {
    background: #FFA000;
    color: #495057;
    border-color: #FFA000;
  }
  .p-button.p-button-warning.p-button-outlined, .p-button-group.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
    background-color: transparent;
    color: #FFC107;
    border: 1px solid;
  }
  .p-button.p-button-warning.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-warning > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(255, 193, 7, 0.04);
    color: #FFC107;
    border: 1px solid;
  }
  .p-button.p-button-warning.p-button-outlined:not(:disabled):active, .p-button-group.p-button-warning > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(255, 193, 7, 0.16);
    color: #FFC107;
    border: 1px solid;
  }
  .p-button.p-button-warning.p-button-text, .p-button-group.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
    background-color: transparent;
    color: #FFC107;
    border-color: transparent;
  }
  .p-button.p-button-warning.p-button-text:not(:disabled):hover, .p-button-group.p-button-warning > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(:disabled):hover {
    background: rgba(255, 193, 7, 0.04);
    border-color: transparent;
    color: #FFC107;
  }
  .p-button.p-button-warning.p-button-text:not(:disabled):active, .p-button-group.p-button-warning > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(:disabled):active {
    background: rgba(255, 193, 7, 0.16);
    border-color: transparent;
    color: #FFC107;
  }
  .p-button.p-button-help, .p-button-group.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
    color: #ffffff;
    background: #9C27B0;
    border: 1px solid #9C27B0;
  }
  .p-button.p-button-help:not(:disabled):hover, .p-button-group.p-button-help > .p-button:not(:disabled):hover, .p-splitbutton.p-button-help > .p-button:not(:disabled):hover {
    background: #8E24AA;
    color: #ffffff;
    border-color: #8E24AA;
  }
  .p-button.p-button-help:not(:disabled):focus, .p-button-group.p-button-help > .p-button:not(:disabled):focus, .p-splitbutton.p-button-help > .p-button:not(:disabled):focus {
    box-shadow: 0 0 0 0.2rem #CE93D8;
  }
  .p-button.p-button-help:not(:disabled):active, .p-button-group.p-button-help > .p-button:not(:disabled):active, .p-splitbutton.p-button-help > .p-button:not(:disabled):active {
    background: #7B1FA2;
    color: #ffffff;
    border-color: #7B1FA2;
  }
  .p-button.p-button-help.p-button-outlined, .p-button-group.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined {
    background-color: transparent;
    color: #9C27B0;
    border: 1px solid;
  }
  .p-button.p-button-help.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-help > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(156, 39, 176, 0.04);
    color: #9C27B0;
    border: 1px solid;
  }
  .p-button.p-button-help.p-button-outlined:not(:disabled):active, .p-button-group.p-button-help > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(156, 39, 176, 0.16);
    color: #9C27B0;
    border: 1px solid;
  }
  .p-button.p-button-help.p-button-text, .p-button-group.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text {
    background-color: transparent;
    color: #9C27B0;
    border-color: transparent;
  }
  .p-button.p-button-help.p-button-text:not(:disabled):hover, .p-button-group.p-button-help > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-text:not(:disabled):hover {
    background: rgba(156, 39, 176, 0.04);
    border-color: transparent;
    color: #9C27B0;
  }
  .p-button.p-button-help.p-button-text:not(:disabled):active, .p-button-group.p-button-help > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-text:not(:disabled):active {
    background: rgba(156, 39, 176, 0.16);
    border-color: transparent;
    color: #9C27B0;
  }
  .p-button.p-button-danger, .p-button-group.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
    color: #ffffff;
    background: #f44336;
    border: 1px solid #f44336;
  }
  .p-button.p-button-danger:not(:disabled):hover, .p-button-group.p-button-danger > .p-button:not(:disabled):hover, .p-splitbutton.p-button-danger > .p-button:not(:disabled):hover {
    background: #e53935;
    color: #ffffff;
    border-color: #e53935;
  }
  .p-button.p-button-danger:not(:disabled):focus, .p-button-group.p-button-danger > .p-button:not(:disabled):focus, .p-splitbutton.p-button-danger > .p-button:not(:disabled):focus {
    box-shadow: 0 0 0 0.2rem rgb(253.2617924528, 225.2924528302, 223.2382075472);
  }
  .p-button.p-button-danger:not(:disabled):active, .p-button-group.p-button-danger > .p-button:not(:disabled):active, .p-splitbutton.p-button-danger > .p-button:not(:disabled):active {
    background: #d32f2f;
    color: #ffffff;
    border-color: #d32f2f;
  }
  .p-button.p-button-danger.p-button-outlined, .p-button-group.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
    background-color: transparent;
    color: #f44336;
    border: 1px solid;
  }
  .p-button.p-button-danger.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-danger > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(244, 67, 54, 0.04);
    color: #f44336;
    border: 1px solid;
  }
  .p-button.p-button-danger.p-button-outlined:not(:disabled):active, .p-button-group.p-button-danger > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(244, 67, 54, 0.16);
    color: #f44336;
    border: 1px solid;
  }
  .p-button.p-button-danger.p-button-text, .p-button-group.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
    background-color: transparent;
    color: #f44336;
    border-color: transparent;
  }
  .p-button.p-button-danger.p-button-text:not(:disabled):hover, .p-button-group.p-button-danger > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(:disabled):hover {
    background: rgba(244, 67, 54, 0.04);
    border-color: transparent;
    color: #f44336;
  }
  .p-button.p-button-danger.p-button-text:not(:disabled):active, .p-button-group.p-button-danger > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(:disabled):active {
    background: rgba(244, 67, 54, 0.16);
    border-color: transparent;
    color: #f44336;
  }
  .p-button.p-button-link {
    color: #571836;
    background: transparent;
    border: transparent;
  }
  .p-button.p-button-link:not(:disabled):hover {
    background: transparent;
    color: #571836;
    border-color: transparent;
  }
  .p-button.p-button-link:not(:disabled):hover .p-button-label {
    text-decoration: underline;
  }
  .p-button.p-button-link:not(:disabled):focus {
    background: transparent;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
    border-color: transparent;
  }
  .p-button.p-button-link:not(:disabled):active {
    background: transparent;
    color: #571836;
    border-color: transparent;
  }
  .p-speeddial-button.p-button.p-button-icon-only {
    width: 4rem;
    height: 4rem;
  }
  .p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
    font-size: 1.3rem;
  }
  .p-speeddial-button.p-button.p-button-icon-only .p-icon {
    width: 1.3rem;
    height: 1.3rem;
  }
  .p-speeddial-list {
    outline: 0 none;
  }
  .p-speeddial-item.p-focus > .p-speeddial-action {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-speeddial-action {
    width: 3rem;
    height: 3rem;
    background: #495057;
    color: #fff;
  }
  .p-speeddial-action:hover {
    background: #343a40;
    color: #fff;
  }
  .p-speeddial-direction-up .p-speeddial-item {
    margin: 0.25rem 0;
  }
  .p-speeddial-direction-up .p-speeddial-item:first-child {
    margin-bottom: 0.5rem;
  }
  .p-speeddial-direction-down .p-speeddial-item {
    margin: 0.25rem 0;
  }
  .p-speeddial-direction-down .p-speeddial-item:first-child {
    margin-top: 0.5rem;
  }
  .p-speeddial-direction-left .p-speeddial-item {
    margin: 0 0.25rem;
  }
  .p-speeddial-direction-left .p-speeddial-item:first-child {
    margin-right: 0.5rem;
  }
  .p-speeddial-direction-right .p-speeddial-item {
    margin: 0 0.25rem;
  }
  .p-speeddial-direction-right .p-speeddial-item:first-child {
    margin-left: 0.5rem;
  }
  .p-speeddial-circle .p-speeddial-item,
  .p-speeddial-semi-circle .p-speeddial-item,
  .p-speeddial-quarter-circle .p-speeddial-item {
    margin: 0;
  }
  .p-speeddial-circle .p-speeddial-item:first-child, .p-speeddial-circle .p-speeddial-item:last-child,
  .p-speeddial-semi-circle .p-speeddial-item:first-child,
  .p-speeddial-semi-circle .p-speeddial-item:last-child,
  .p-speeddial-quarter-circle .p-speeddial-item:first-child,
  .p-speeddial-quarter-circle .p-speeddial-item:last-child {
    margin: 0;
  }
  .p-speeddial-mask {
    background-color: rgba(0, 0, 0, 0.4);
  }
  .p-splitbutton {
    border-radius: 3px;
  }
  .p-splitbutton.p-button-rounded {
    border-radius: 2rem;
  }
  .p-splitbutton.p-button-rounded > .p-button {
    border-radius: 2rem;
  }
  .p-splitbutton.p-button-raised {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .p-carousel .p-carousel-content .p-carousel-prev,
  .p-carousel .p-carousel-content .p-carousel-next {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin: 0.5rem;
  }
  .p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
  .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
  }
  .p-carousel .p-carousel-content .p-carousel-prev:focus-visible,
  .p-carousel .p-carousel-content .p-carousel-next:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-carousel .p-carousel-indicators {
    padding: 1rem;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #e9ecef;
    width: 2rem;
    height: 0.5rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
    background: #dee2e6;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background: #C5357B;
    color: #ffffff;
  }
  .p-datatable .p-paginator-top {
    border-width: 1px 0 1px 0;
    border-radius: 0;
  }
  .p-datatable .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-datatable .p-datatable-header {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-datatable .p-datatable-footer {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-datatable .p-datatable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
  }
  .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #6c757d;
    margin-left: 0.5rem;
  }
  .p-datatable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #ffffff;
    background: #C5357B;
    margin-left: 0.5rem;
  }
  .p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;
  }
  .p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: #6c757d;
  }
  .p-datatable .p-sortable-column.p-highlight {
    background: #f8f9fa;
    color: #C5357B;
  }
  .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #C5357B;
  }
  .p-datatable .p-sortable-column.p-highlight:hover {
    background: #e9ecef;
    color: #C5357B;
  }
  .p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: #C5357B;
  }
  .p-datatable .p-sortable-column:focus-visible {
    box-shadow: inset 0 0 0 0.15rem #bfd1f6;
    outline: 0 none;
  }
  .p-datatable .p-datatable-tbody > tr {
    background: #ffffff;
    color: #495057;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus-visible,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus-visible,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus-visible,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
    margin-right: 0.5rem;
  }
  .p-datatable .p-datatable-tbody > tr:focus-visible {
    outline: 0.15rem solid #bfd1f6;
    outline-offset: -0.15rem;
  }
  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #C5357B;
    color: #ffffff;
  }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 #C5357B;
  }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 #C5357B;
  }
  .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;
  }
  .p-datatable .p-column-resizer-helper {
    background: #C5357B;
  }
  .p-datatable .p-datatable-scrollable-header,
  .p-datatable .p-datatable-scrollable-footer {
    background: #f8f9fa;
  }
  .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
  .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-scroller-viewport > .p-scroller > .p-datatable-table > .p-datatable-thead,
  .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-scroller-viewport > .p-scroller > .p-datatable-table > .p-datatable-tfoot {
    background-color: #f8f9fa;
  }
  .p-datatable .p-datatable-loading-icon {
    font-size: 2rem;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-footer {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-paginator-top {
    border-width: 0 1px 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
    border-width: 1px 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
    border-width: 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
    border-width: 1px 0 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
    border-width: 1px 1px 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
    border-width: 1px 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td:last-child {
    border-width: 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
    border-width: 1px 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
    border-width: 1px 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td {
    border-width: 0 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td:last-child {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
    border-width: 0 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td {
    border-width: 0 0 0 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td:last-child {
    border-width: 0 1px 0 1px;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
    background: #ffffff;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
    background: #C5357B;
    color: #ffffff;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
    color: #ffffff;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
    color: #ffffff;
  }
  .p-datatable.p-datatable-sm .p-datatable-header {
    padding: 0.5rem 0.5rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: 0.5rem 0.5rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 0.5rem 0.5rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
    padding: 0.5rem 0.5rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-footer {
    padding: 0.5rem 0.5rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-header {
    padding: 1.25rem 1.25rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
    padding: 1.25rem 1.25rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
    padding: 1.25rem 1.25rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
    padding: 1.25rem 1.25rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-footer {
    padding: 1.25rem 1.25rem;
  }
  .p-dataview .p-paginator-top {
    border-width: 1px 0 1px 0;
    border-radius: 0;
  }
  .p-dataview .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-dataview .p-dataview-header {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-dataview .p-dataview-content {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    padding: 0;
  }
  .p-dataview .p-dataview-footer {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-dataview .p-dataview-loading-icon {
    font-size: 2rem;
  }
  .p-dataview .p-dataview-emptymessage {
    padding: 1rem;
  }
  .p-column-filter-row .p-column-filter-menu-button,
  .p-column-filter-row .p-column-filter-clear-button {
    margin-left: 0.5rem;
  }
  .p-column-filter-menu-button {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-column-filter-menu-button:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
  }
  .p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
    background: #e9ecef;
    color: #495057;
  }
  .p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: #C5357B;
    color: #ffffff;
  }
  .p-column-filter-menu-button:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-column-filter-clear-button {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-column-filter-clear-button:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
  }
  .p-column-filter-clear-button:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-column-filter-overlay {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    min-width: 12.5rem;
  }
  .p-column-filter-overlay .p-column-filter-row-items {
    padding: 0.5rem 0;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:first-child {
    margin-top: 0;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
    color: #ffffff;
    background: #C5357B;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
    color: #495057;
    background: #e9ecef;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #bfd1f6;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }
  .p-column-filter-overlay-menu .p-column-filter-operator {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: #495057;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint {
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
    margin-bottom: 0.5rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
    margin-top: 0.5rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
    border-bottom: 0 none;
  }
  .p-column-filter-overlay-menu .p-column-filter-add-rule {
    padding: 0.5rem 1rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-buttonbar {
    padding: 1rem;
  }
  .p-orderlist .p-orderlist-controls {
    padding: 1rem;
  }
  .p-orderlist .p-orderlist-controls .p-button {
    margin-bottom: 0.5rem;
  }
  .p-orderlist .p-orderlist-list-container {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    outline-color: transparent;
  }
  .p-orderlist .p-orderlist-list-container.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
    border-color: #C5357B;
  }
  .p-orderlist .p-orderlist-header {
    color: #495057;
    padding: 1rem;
    font-weight: 600;
  }
  .p-orderlist .p-orderlist-header .p-orderlist-title {
    font-weight: 600;
  }
  .p-orderlist .p-orderlist-filter-container {
    padding: 1rem;
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-bottom: 0 none;
  }
  .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
    padding-right: 1.5rem;
  }
  .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
    right: 0.5rem;
    color: #495057;
  }
  .p-orderlist .p-orderlist-list {
    color: #495057;
    padding: 0.5rem 0;
    outline: 0 none;
  }
  .p-orderlist .p-orderlist-list:not(:first-child) {
    border-top: 1px solid #dee2e6;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0.5rem 1rem;
    margin: 0;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item:first-child {
    margin-top: 0;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item.p-focus {
    color: #495057;
    background: #f8f9fa;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    color: #ffffff;
    background: #C5357B;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight.p-focus {
    background: rgba(197, 53, 123, 0.24);
  }
  .p-orderlist .p-orderlist-list .p-orderlist-empty-message {
    padding: 0.5rem 1rem;
    color: #495057;
  }
  .p-orderlist .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;
  }
  .p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even) {
    background: #e9ecef;
  }
  .p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even):hover {
    background: #e9ecef;
  }
  .p-orderlist-item.cdk-drag-preview {
    padding: 0.5rem 1rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    border: 0 none;
    color: #495057;
    background: #ffffff;
    margin: 0;
  }
  .p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;
  }
  .p-organizationchart .p-organizationchart-node-content.p-highlight {
    background: #C5357B;
    color: #ffffff;
  }
  .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
    color: rgb(96.53, 25.97, 60.27);
  }
  .p-organizationchart .p-organizationchart-line-down {
    background: #dee2e6;
  }
  .p-organizationchart .p-organizationchart-line-left {
    border-right: 1px solid #dee2e6;
    border-color: #dee2e6;
  }
  .p-organizationchart .p-organizationchart-line-top {
    border-top: 1px solid #dee2e6;
    border-color: #dee2e6;
  }
  .p-organizationchart .p-organizationchart-node-content {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    padding: 1rem;
  }
  .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
    background: inherit;
    color: inherit;
    border-radius: 50%;
  }
  .p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-paginator {
    background: #ffffff;
    color: #6c757d;
    border: solid #e9ecef;
    border-width: 0;
    padding: 0.5rem 1rem;
    border-radius: 3px;
  }
  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    background-color: transparent;
    border: 0 none;
    color: #6c757d;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
    border-color: transparent;
    color: #6c757d;
  }
  .p-paginator .p-paginator-first {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-paginator .p-paginator-last {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-paginator .p-dropdown {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 2.357rem;
  }
  .p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0;
  }
  .p-paginator .p-paginator-page-input {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .p-paginator .p-paginator-page-input .p-inputtext {
    max-width: 2.357rem;
  }
  .p-paginator .p-paginator-current {
    background-color: transparent;
    border: 0 none;
    color: #6c757d;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    padding: 0 0.5rem;
  }
  .p-paginator .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0 none;
    color: #6c757d;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #C5357B;
    border-color: #C5357B;
    color: #ffffff;
  }
  .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: #e9ecef;
    border-color: transparent;
    color: #6c757d;
  }
  .p-picklist .p-picklist-buttons {
    padding: 1rem;
  }
  .p-picklist .p-picklist-buttons .p-button {
    margin-bottom: 0.5rem;
  }
  .p-picklist .p-picklist-list-wrapper {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    outline-color: transparent;
  }
  .p-picklist .p-picklist-list-wrapper.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
    border-color: #C5357B;
  }
  .p-picklist .p-picklist-header {
    color: #495057;
    padding: 1rem;
    font-weight: 600;
  }
  .p-picklist .p-picklist-header .p-picklist-title {
    font-weight: 600;
  }
  .p-picklist .p-picklist-filter-container {
    padding: 1rem;
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-bottom: 0 none;
  }
  .p-picklist .p-picklist-filter-container .p-picklist-filter-input {
    padding-right: 1.5rem;
  }
  .p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
    right: 0.5rem;
    color: #495057;
  }
  .p-picklist .p-picklist-list {
    color: #495057;
    padding: 0.5rem 0;
    outline: 0 none;
  }
  .p-picklist .p-picklist-list:not(:first-child) {
    border-top: 1px solid #dee2e6;
  }
  .p-picklist .p-picklist-list .p-picklist-item {
    padding: 0.5rem 1rem;
    margin: 0;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-picklist .p-picklist-list .p-picklist-item:first-child {
    margin-top: 0;
  }
  .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;
  }
  .p-picklist .p-picklist-list .p-picklist-item.p-focus {
    color: #495057;
    background: #f8f9fa;
  }
  .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    color: #ffffff;
    background: #C5357B;
  }
  .p-picklist .p-picklist-list .p-picklist-item.p-highlight.p-focus {
    background: rgba(197, 53, 123, 0.24);
  }
  .p-picklist .p-picklist-list .p-picklist-empty-message {
    padding: 0.5rem 1rem;
    color: #495057;
  }
  .p-picklist .p-picklist-list:not(.cdk-drop-list-dragging) .p-picklist-item:not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;
  }
  .p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even) {
    background: #e9ecef;
  }
  .p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even):hover {
    background: #e9ecef;
  }
  .p-picklist-item.cdk-drag-preview {
    padding: 0.5rem 1rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    border: 0 none;
    color: #495057;
    background: #ffffff;
    margin: 0;
  }
  .p-timeline .p-timeline-event-marker {
    border: 2px solid #C5357B;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background-color: #ffffff;
  }
  .p-timeline .p-timeline-event-connector {
    background-color: #dee2e6;
  }
  .p-timeline.p-timeline-vertical .p-timeline-event-opposite,
  .p-timeline.p-timeline-vertical .p-timeline-event-content {
    padding: 0 1rem;
  }
  .p-timeline.p-timeline-vertical .p-timeline-event-connector {
    width: 2px;
  }
  .p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
  .p-timeline.p-timeline-horizontal .p-timeline-event-content {
    padding: 1rem 0;
  }
  .p-timeline.p-timeline-horizontal .p-timeline-event-connector {
    height: 2px;
  }
  .p-tree {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    padding: 1rem;
    border-radius: 3px;
  }
  .p-tree .p-tree-container .p-treenode {
    padding: 0.143rem;
    outline: 0 none;
  }
  .p-tree .p-tree-container .p-treenode:focus > .p-treenode-content {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #bfd1f6;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content {
    border-radius: 3px;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    padding: 0.5rem;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: 0.5rem;
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
    margin-right: 0.5rem;
    color: #6c757d;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #495057;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-variant-filled .p-checkbox-box {
    background-color: #f8f9fa;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-variant-filled .p-checkbox-box.p-highlight {
    background: #C5357B;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box:hover {
    background-color: #f8f9fa;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box.p-highlight:hover {
    background: #571836;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: #C5357B;
    color: #ffffff;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: #ffffff;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
    color: #ffffff;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
    background: #e9ecef;
    color: #495057;
  }
  .p-tree .p-tree-filter-container {
    margin-bottom: 0.5rem;
  }
  .p-tree .p-tree-filter-container .p-tree-filter {
    width: 100%;
    padding-right: 1.5rem;
  }
  .p-tree .p-tree-filter-container .p-tree-filter-icon {
    right: 0.5rem;
    color: #495057;
  }
  .p-tree .p-treenode-children {
    padding: 0 0 0 1rem;
  }
  .p-tree .p-tree-loading-icon {
    font-size: 2rem;
  }
  .p-tree .p-tree-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
    background-color: rgb(157.6, 42.4, 98.4);
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
    border-radius: 3px;
    border: 1px solid #dee2e6;
    background-color: #ffffff;
    color: #495057;
    padding: 0.5rem;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
    background-color: #C5357B;
    color: #ffffff;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: #ffffff;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: 0.5rem;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
    color: #6c757d;
    margin-right: 0.5rem;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
    background-color: inherit;
    color: inherit;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-treetable .p-paginator-top {
    border-width: 1px 0 1px 0;
    border-radius: 0;
  }
  .p-treetable .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-treetable .p-treetable-header {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-treetable .p-treetable-footer {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-treetable .p-treetable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-treetable .p-treetable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
  }
  .p-treetable .p-sortable-column {
    outline-color: #bfd1f6;
  }
  .p-treetable .p-sortable-column .p-sortable-column-icon {
    color: #6c757d;
    margin-left: 0.5rem;
  }
  .p-treetable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #ffffff;
    background: #C5357B;
    margin-left: 0.5rem;
  }
  .p-treetable .p-sortable-column:not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;
  }
  .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: #6c757d;
  }
  .p-treetable .p-sortable-column.p-highlight {
    background: #f8f9fa;
    color: #C5357B;
  }
  .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #C5357B;
  }
  .p-treetable .p-treetable-tbody > tr {
    background: #ffffff;
    color: #495057;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-treetable .p-treetable-tbody > tr > td {
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-right: 0.5rem;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler.p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #495057;
  }
  .p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox.p-variant-filled .p-checkbox-box {
    background-color: #f8f9fa;
  }
  .p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox.p-variant-filled .p-checkbox-box.p-highlight {
    background: #C5357B;
  }
  .p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box:hover {
    background-color: #f8f9fa;
  }
  .p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box.p-highlight:hover {
    background: #571836;
  }
  .p-treetable .p-treetable-tbody > tr:focus-visible {
    outline: 0.15rem solid #bfd1f6;
    outline-offset: -0.15rem;
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight {
    background: #C5357B;
    color: #ffffff;
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
    color: #ffffff;
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
    color: #ffffff;
  }
  .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;
  }
  .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
    color: #495057;
  }
  .p-treetable .p-column-resizer-helper {
    background: #C5357B;
  }
  .p-treetable .p-treetable-scrollable-header,
  .p-treetable .p-treetable-scrollable-footer {
    background: #f8f9fa;
  }
  .p-treetable .p-treetable-loading-icon {
    font-size: 2rem;
  }
  .p-treetable .p-treetable-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-treetable.p-treetable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-footer {
    border-width: 0 1px 1px 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-top {
    border-width: 0 1px 0 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-bottom {
    border-width: 0 1px 1px 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
    border-width: 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
    border-width: 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
    border-width: 1px;
  }
  .p-treetable.p-treetable-sm .p-treetable-header {
    padding: 0.875rem 0.875rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
    padding: 0.5rem 0.5rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
    padding: 0.5rem 0.5rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
    padding: 0.5rem 0.5rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-footer {
    padding: 0.5rem 0.5rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-header {
    padding: 1.25rem 1.25rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
    padding: 1.25rem 1.25rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
    padding: 1.25rem 1.25rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
    padding: 1.25rem 1.25rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-footer {
    padding: 1.25rem 1.25rem;
  }
  .p-virtualscroller .p-virtualscroller-header {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-virtualscroller .p-virtualscroller-content {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    padding: 0;
  }
  .p-virtualscroller .p-virtualscroller-footer {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1rem;
    border: 1px solid #dee2e6;
    color: #495057;
    background: #f8f9fa;
    font-weight: 600;
    border-radius: 3px;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    margin-right: 0.5rem;
  }
  .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem #bfd1f6;
  }
  .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background: #e9ecef;
    border-color: 1px solid #dee2e6;
    color: #495057;
  }
  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #f8f9fa;
    border-color: #dee2e6;
    color: #495057;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-color: #dee2e6;
    background: #e9ecef;
    color: #495057;
  }
  .p-accordion .p-accordion-content {
    padding: 1rem;
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-accordion p-accordiontab .p-accordion-tab {
    margin-bottom: 0;
  }
  .p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
    border-radius: 0;
  }
  .p-accordion p-accordiontab .p-accordion-content {
    border-radius: 0;
  }
  .p-accordion p-accordiontab:not(:first-child) .p-accordion-header .p-accordion-header-link {
    border-top: 0 none;
  }
  .p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-top: 0 none;
  }
  .p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-accordion p-accordiontab:last-child .p-accordion-content {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-card {
    background: #ffffff;
    color: #495057;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 3px;
  }
  .p-card .p-card-body {
    padding: 1rem;
  }
  .p-card .p-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .p-card .p-card-subtitle {
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: #6c757d;
  }
  .p-card .p-card-content {
    padding: 1rem 0;
  }
  .p-card .p-card-footer {
    padding: 1rem 0 0 0;
  }
  .p-divider .p-divider-content {
    background-color: #ffffff;
  }
  .p-divider.p-divider-horizontal {
    margin: 1rem 0;
    padding: 0 1rem;
  }
  .p-divider.p-divider-horizontal:before {
    border-top: 1px #dee2e6;
  }
  .p-divider.p-divider-horizontal .p-divider-content {
    padding: 0 0.5rem;
  }
  .p-divider.p-divider-vertical {
    margin: 0 1rem;
    padding: 1rem 0;
  }
  .p-divider.p-divider-vertical:before {
    border-left: 1px #dee2e6;
  }
  .p-divider.p-divider-vertical .p-divider-content {
    padding: 0.5rem 0;
  }
  .p-fieldset {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    border-radius: 3px;
  }
  .p-fieldset .p-fieldset-legend {
    padding: 1rem;
    border: 1px solid #dee2e6;
    color: #495057;
    background: #f8f9fa;
    font-weight: 600;
    border-radius: 3px;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 1rem;
    color: #495057;
    border-radius: 3px;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
    margin-right: 0.5rem;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
    background: #e9ecef;
    border-color: #dee2e6;
    color: #495057;
  }
  .p-fieldset .p-fieldset-content {
    padding: 1rem;
  }
  .p-panel .p-panel-header {
    border: 1px solid #dee2e6;
    padding: 1rem;
    background: #f8f9fa;
    color: #495057;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-panel .p-panel-header .p-panel-title {
    font-weight: 600;
  }
  .p-panel .p-panel-header .p-panel-header-icon {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
  }
  .p-panel .p-panel-header .p-panel-header-icon:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-panel.p-panel-toggleable .p-panel-header {
    padding: 0.5rem 1rem;
  }
  .p-panel .p-panel-content {
    padding: 1rem;
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    border-top: 0 none;
  }
  .p-panel .p-panel-content:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-panel .p-panel-footer {
    padding: 0.5rem 1rem;
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top: 0 none;
  }
  .p-panel .p-panel-icons-end {
    order: 2;
    margin-left: auto;
  }
  .p-panel .p-panel-icons-start {
    order: 0;
    margin-right: 0.5rem;
  }
  .p-panel .p-panel-icons-center {
    order: 2;
    width: 100%;
    text-align: center;
  }
  .p-scrollpanel .p-scrollpanel-bar {
    background: #f8f9fa;
    border: 0 none;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-scrollpanel .p-scrollpanel-bar:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-splitter {
    border: 1px solid #dee2e6;
    background: #ffffff;
    border-radius: 3px;
    color: #495057;
  }
  .p-splitter .p-splitter-gutter {
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    background: #f8f9fa;
  }
  .p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
    background: #dee2e6;
  }
  .p-splitter .p-splitter-gutter .p-splitter-gutter-handle:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-splitter .p-splitter-gutter-resizing {
    background: #dee2e6;
  }
  .p-tabview .p-tabview-nav-content {
    scroll-padding-inline: 2.357rem;
  }
  .p-tabview .p-tabview-nav {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-width: 0 0 2px 0;
  }
  .p-tabview .p-tabview-nav li {
    margin-right: 0;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid #dee2e6;
    border-width: 0 0 2px 0;
    border-color: transparent transparent #dee2e6 transparent;
    background: #ffffff;
    color: #6c757d;
    padding: 1rem;
    font-weight: 600;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    margin: 0 0 -2px 0;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem #bfd1f6;
  }
  .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: #ffffff;
    border-color: #9ba2aa;
    color: #6c757d;
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff;
    border-color: #C5357B;
    color: #C5357B;
  }
  .p-tabview .p-tabview-left-icon {
    margin-right: 0.5rem;
  }
  .p-tabview .p-tabview-right-icon {
    margin-left: 0.5rem;
  }
  .p-tabview .p-tabview-close {
    margin-left: 0.5rem;
  }
  .p-tabview .p-tabview-nav-btn.p-link {
    background: #ffffff;
    color: #C5357B;
    width: 2.357rem;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border-radius: 0;
  }
  .p-tabview .p-tabview-nav-btn.p-link:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem #bfd1f6;
  }
  .p-tabview .p-tabview-panels {
    background: #ffffff;
    padding: 1rem;
    border: 0 none;
    color: #495057;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-toolbar {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    padding: 1rem;
    border-radius: 3px;
    gap: 0.5rem;
  }
  .p-toolbar .p-toolbar-separator {
    margin: 0 0.5rem;
  }
  .p-stepper .p-stepper-nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow-x: auto;
  }
  .p-stepper-vertical .p-stepper-nav {
    flex-direction: column;
  }
  .p-stepper-header {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
  }
  .p-stepper-header:last-of-type {
    flex: initial;
  }
  .p-stepper-header .p-stepper-action {
    border: 0 none;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .p-stepper-header .p-stepper-action:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-stepper.p-stepper-readonly .p-stepper-header {
    cursor: auto;
  }
  .p-stepper-header.p-highlight .p-stepper-action {
    cursor: default;
  }
  .p-stepper-title {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .p-stepper-number {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p-stepper-separator {
    flex: 1 1 0;
  }
  .p-stepper .p-stepper-nav {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .p-stepper .p-stepper-header {
    padding: 0.5rem;
  }
  .p-stepper .p-stepper-header .p-stepper-action {
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    background: #ffffff;
    outline-color: transparent;
  }
  .p-stepper .p-stepper-header .p-stepper-action .p-stepper-number {
    color: #495057;
    border: 1px solid #c8c8c8;
    border-width: 2px;
    background: #ffffff;
    min-width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.143rem;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-stepper .p-stepper-header .p-stepper-action .p-stepper-title {
    margin-left: 0.5rem;
    color: #6c757d;
    font-weight: 600;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-stepper .p-stepper-header .p-stepper-action:not(.p-disabled):focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-stepper .p-stepper-header.p-highlight .p-stepper-number {
    background: #C5357B;
    color: #ffffff;
  }
  .p-stepper .p-stepper-header.p-highlight .p-stepper-title {
    color: #495057;
  }
  .p-stepper .p-stepper-header:not(.p-disabled):focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-stepper .p-stepper-header:has(~ .p-highlight) .p-stepper-separator {
    background-color: #C5357B;
  }
  .p-stepper .p-stepper-panels {
    background: #ffffff;
    padding: 1rem;
    color: #495057;
  }
  .p-stepper .p-stepper-separator {
    background-color: #dee2e6;
    width: 100%;
    height: 2px;
    margin-inline-start: 1rem;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-stepper.p-stepper-vertical {
    display: flex;
    flex-direction: column;
  }
  .p-stepper.p-stepper-vertical .p-stepper-toggleable-content {
    display: flex;
    flex: 1 1 auto;
    background: #ffffff;
    color: #495057;
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel {
    display: flex;
    flex-direction: column;
    flex: initial;
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel.p-stepper-panel-active {
    flex: 1 1 auto;
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-header {
    flex: initial;
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-content {
    width: 100%;
    padding-left: 1rem;
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-separator {
    flex: 0 0 auto;
    width: 2px;
    height: auto;
    margin-inline-start: calc(1.75rem + 2px);
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel:has(~ .p-stepper-panel-active) .p-stepper-separator {
    background-color: #C5357B;
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel:last-of-type .p-stepper-content {
    padding-left: 3rem;
  }
  .p-confirm-popup {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
  }
  .p-confirm-popup .p-confirm-popup-content {
    padding: 1rem;
  }
  .p-confirm-popup .p-confirm-popup-footer {
    text-align: right;
    padding: 0.5rem 1rem;
  }
  .p-confirm-popup .p-confirm-popup-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
  }
  .p-confirm-popup .p-confirm-popup-footer button:last-child {
    margin: 0;
  }
  .p-confirm-popup:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
  }
  .p-confirm-popup:before {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
  }
  .p-confirm-popup.p-confirm-popup-flipped:after {
    border-top-color: #ffffff;
  }
  .p-confirm-popup.p-confirm-popup-flipped:before {
    border-top-color: #ffffff;
  }
  .p-confirm-popup .p-confirm-popup-icon {
    font-size: 1.5rem;
  }
  .p-confirm-popup .p-confirm-popup-icon.p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .p-confirm-popup .p-confirm-popup-message {
    margin-left: 1rem;
  }
  .p-dialog {
    border-radius: 3px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
    border: 0 none;
  }
  .p-dialog .p-dialog-header {
    border-bottom: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    padding: 1.5rem;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 600;
    font-size: 1.25rem;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-right: 0.5rem;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0;
  }
  .p-dialog .p-dialog-content {
    background: #ffffff;
    color: #495057;
    padding: 0 1.5rem;
  }
  .p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-dialog .p-dialog-footer {
    border-top: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    padding: 1.5rem;
    text-align: right;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-dialog .p-dialog-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
  }
  .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem;
  }
  .p-dialog.p-confirm-dialog .p-confirm-dialog-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 1rem;
  }
  .p-overlaypanel {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
  }
  .p-overlaypanel .p-overlaypanel-content {
    padding: 1rem;
  }
  .p-overlaypanel .p-overlaypanel-close {
    background: #C5357B;
    color: #ffffff;
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
    position: absolute;
    top: -1rem;
    right: -1rem;
  }
  .p-overlaypanel .p-overlaypanel-close:enabled:hover {
    background: #9b2a61;
    color: #ffffff;
  }
  .p-overlaypanel:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
  }
  .p-overlaypanel:before {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: rgb(242.25, 242.25, 242.25);
  }
  .p-overlaypanel.p-overlaypanel-flipped:after {
    border-top-color: #ffffff;
  }
  .p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: #ffffff;
  }
  .p-sidebar {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
  }
  .p-sidebar .p-sidebar-header {
    padding: 1rem;
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close,
  .p-sidebar .p-sidebar-header .p-sidebar-icon {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
  .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close:focus-visible,
  .p-sidebar .p-sidebar-header .p-sidebar-icon:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-sidebar .p-sidebar-header + .p-sidebar-content {
    padding-top: 0;
  }
  .p-sidebar .p-sidebar-content {
    padding: 1rem;
  }
  .p-sidebar .p-sidebar-footer {
    padding: 1rem;
  }
  .p-tooltip .p-tooltip-text {
    background: #495057;
    color: #ffffff;
    padding: 0.5rem 0.5rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
  .p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #495057;
  }
  .p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #495057;
  }
  .p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #495057;
  }
  .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #495057;
  }
  .p-fileupload .p-fileupload-buttonbar {
    background: #f8f9fa;
    padding: 1rem;
    border: 1px solid #dee2e6;
    color: #495057;
    border-bottom: 0 none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-fileupload .p-fileupload-buttonbar .p-button {
    margin-right: 0.5rem;
  }
  .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-fileupload .p-fileupload-content {
    background: #ffffff;
    padding: 2rem 1rem;
    border: 1px solid #dee2e6;
    color: #495057;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-fileupload .p-fileupload-content.p-fileupload-highlight {
    border-color: 1px dashed #e9ecef;
    border-style: dashed;
    background-color: #C5357B;
  }
  .p-fileupload .p-progressbar {
    height: 0.25rem;
  }
  .p-fileupload .p-fileupload-row > div {
    padding: 1rem 1rem;
  }
  .p-fileupload.p-fileupload-advanced .p-message {
    margin-top: 0;
  }
  .p-fileupload-choose:not(.p-disabled):hover {
    background: #9b2a61;
    color: #ffffff;
    border-color: #9b2a61;
  }
  .p-fileupload-choose:not(.p-disabled):active {
    background: #571836;
    color: #ffffff;
    border-color: #571836;
  }
  .p-breadcrumb {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    padding: 1rem;
  }
  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link {
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-icon {
    color: #6c757d;
  }
  .p-breadcrumb .p-breadcrumb-list li.p-menuitem-separator {
    margin: 0 0.5rem 0 0.5rem;
    color: #495057;
  }
  .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-text {
    color: #495057;
  }
  .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-icon {
    color: #6c757d;
  }
  .p-contextmenu {
    padding: 0.25rem 0;
    background: #ffffff;
    color: #495057;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    width: 12.5rem;
  }
  .p-contextmenu .p-contextmenu-root-list {
    outline: 0 none;
  }
  .p-contextmenu .p-submenu-list {
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
  .p-contextmenu .p-menuitem > .p-menuitem-content {
    color: #495057;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #495057;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #6c757d;
    margin-right: 0.5rem;
  }
  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #495057;
    background: #e9ecef;
  }
  .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-contextmenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #e9ecef;
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #495057;
    background: #f8f9fa;
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #495057;
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #495057;
    background: #e9ecef;
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-contextmenu .p-menuitem-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }
  .p-contextmenu .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-contextmenu .p-submenu-icon.p-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
  .p-dock .p-dock-list-container {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
  }
  .p-dock .p-dock-list-container .p-dock-list {
    outline: 0 none;
  }
  .p-dock .p-dock-item {
    padding: 0.5rem;
    border-radius: 3px;
  }
  .p-dock .p-dock-item.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #bfd1f6;
  }
  .p-dock .p-dock-link {
    width: 4rem;
    height: 4rem;
  }
  .p-dock.p-dock-top .p-dock-item-second-prev,
  .p-dock.p-dock-top .p-dock-item-second-next, .p-dock.p-dock-bottom .p-dock-item-second-prev,
  .p-dock.p-dock-bottom .p-dock-item-second-next {
    margin: 0 0.9rem;
  }
  .p-dock.p-dock-top .p-dock-item-prev,
  .p-dock.p-dock-top .p-dock-item-next, .p-dock.p-dock-bottom .p-dock-item-prev,
  .p-dock.p-dock-bottom .p-dock-item-next {
    margin: 0 1.3rem;
  }
  .p-dock.p-dock-top .p-dock-item-current, .p-dock.p-dock-bottom .p-dock-item-current {
    margin: 0 1.5rem;
  }
  .p-dock.p-dock-left .p-dock-item-second-prev,
  .p-dock.p-dock-left .p-dock-item-second-next, .p-dock.p-dock-right .p-dock-item-second-prev,
  .p-dock.p-dock-right .p-dock-item-second-next {
    margin: 0.9rem 0;
  }
  .p-dock.p-dock-left .p-dock-item-prev,
  .p-dock.p-dock-left .p-dock-item-next, .p-dock.p-dock-right .p-dock-item-prev,
  .p-dock.p-dock-right .p-dock-item-next {
    margin: 1.3rem 0;
  }
  .p-dock.p-dock-left .p-dock-item-current, .p-dock.p-dock-right .p-dock-item-current {
    margin: 1.5rem 0;
  }
  @media screen and (max-width: 960px) {
    .p-dock.p-dock-top .p-dock-list-container, .p-dock.p-dock-bottom .p-dock-list-container {
      overflow-x: auto;
      width: 100%;
    }
    .p-dock.p-dock-top .p-dock-list-container .p-dock-list, .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
      margin: 0 auto;
    }
    .p-dock.p-dock-left .p-dock-list-container, .p-dock.p-dock-right .p-dock-list-container {
      overflow-y: auto;
      height: 100%;
    }
    .p-dock.p-dock-left .p-dock-list-container .p-dock-list, .p-dock.p-dock-right .p-dock-list-container .p-dock-list {
      margin: auto 0;
    }
    .p-dock .p-dock-list .p-dock-item {
      transform: none;
      margin: 0;
    }
  }
  .p-megamenu {
    padding: 0.5rem;
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #dee2e6;
    border-radius: 3px;
  }
  .p-megamenu .p-megamenu-root-list {
    outline: 0 none;
  }
  .p-megamenu .p-menuitem > .p-menuitem-content {
    color: #495057;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #495057;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #6c757d;
    margin-right: 0.5rem;
  }
  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #495057;
    background: #e9ecef;
  }
  .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-megamenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #e9ecef;
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #495057;
    background: #f8f9fa;
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #495057;
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #495057;
    background: #e9ecef;
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-megamenu .p-megamenu-panel {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
  .p-megamenu .p-submenu-header {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #495057;
    background: #ffffff;
    font-weight: 600;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-megamenu .p-submenu-list {
    padding: 0.25rem 0;
    width: 12.5rem;
  }
  .p-megamenu .p-submenu-list .p-menuitem-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }
  .p-megamenu.p-megamenu-vertical {
    width: 12.5rem;
    padding: 0.25rem 0;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content {
    color: #495057;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #6c757d;
    margin-right: 0.5rem;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
    margin-left: 0.5rem;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #495057;
    background: #e9ecef;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-menu {
    padding: 0.25rem 0;
    background: #ffffff;
    color: #495057;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    width: 12.5rem;
  }
  .p-menu .p-menuitem > .p-menuitem-content {
    color: #495057;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #495057;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #6c757d;
    margin-right: 0.5rem;
  }
  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-menu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #495057;
    background: #e9ecef;
  }
  .p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-menu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #e9ecef;
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #495057;
    background: #f8f9fa;
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #495057;
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #495057;
    background: #e9ecef;
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-menu.p-menu-overlay {
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-menu .p-submenu-header {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #495057;
    background: #ffffff;
    font-weight: 600;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .p-menu .p-menuitem-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }
  .p-menu .p-menuitem-badge {
    background: #C5357B;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 3px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .p-menubar {
    padding: 0.5rem;
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #dee2e6;
    border-radius: 3px;
  }
  .p-menubar .p-menubar-root-list {
    outline: 0 none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content {
    color: #495057;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #6c757d;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
    margin-left: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #495057;
    background: #e9ecef;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
  .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-menubar .p-menuitem > .p-menuitem-content {
    color: #495057;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #495057;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #6c757d;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-menubar .p-menuitem.p-highlight > .p-menuitem-content {
    color: #495057;
    background: #e9ecef;
  }
  .p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-menubar .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #e9ecef;
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #495057;
    background: #f8f9fa;
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #495057;
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #495057;
    background: #e9ecef;
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-menubar .p-submenu-list {
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
    width: 12.5rem;
    border-radius: 3px;
  }
  .p-menubar .p-submenu-list .p-menuitem-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }
  .p-menubar .p-submenu-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  @media screen and (max-width: 960px) {
    .p-menubar {
      position: relative;
    }
    .p-menubar .p-menubar-button {
      display: flex;
      width: 2rem;
      height: 2rem;
      color: #6c757d;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }
    .p-menubar .p-menubar-button:hover {
      color: #6c757d;
      background: #e9ecef;
    }
    .p-menubar .p-menubar-button:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #bfd1f6;
    }
    .p-menubar .p-menubar-root-list {
      position: absolute;
      display: none;
      padding: 0.25rem 0;
      background: #ffffff;
      border: 0 none;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
      width: 100%;
    }
    .p-menubar .p-menubar-root-list .p-menuitem-separator {
      border-top: 1px solid #dee2e6;
      margin: 0.25rem 0;
    }
    .p-menubar .p-menubar-root-list .p-submenu-icon {
      font-size: 0.875rem;
    }
    .p-menubar .p-menubar-root-list .p-menuitem {
      width: 100%;
      position: static;
    }
    .p-menubar .p-menubar-root-list .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
      margin-left: auto;
      transition: transform 0.2s;
    }
    .p-menubar .p-menubar-root-list .p-menuitem.p-menuitem-active > .p-menuitem-content > .p-menuitem-link > .p-submenu-icon {
      transform: rotate(-180deg);
    }
    .p-menubar .p-menubar-root-list .p-submenu-list {
      width: 100%;
      position: static;
      box-shadow: none;
      border: 0 none;
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
      transition: transform 0.2s;
      transform: rotate(90deg);
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-content > .p-menuitem-link > .p-submenu-icon {
      transform: rotate(-90deg);
    }
    .p-menubar .p-menubar-root-list .p-menuitem {
      width: 100%;
      position: static;
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
      padding-left: 2.25rem;
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
      padding-left: 3.75rem;
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
      padding-left: 5.25rem;
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
      padding-left: 6.75rem;
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
      padding-left: 8.25rem;
    }
    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
      display: flex;
      flex-direction: column;
      top: 100%;
      left: 0;
      z-index: 1;
    }
  }
  .p-panelmenu .p-panelmenu-header {
    outline: 0 none;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
    border: 1px solid #dee2e6;
    color: #495057;
    background: #f8f9fa;
    border-radius: 3px;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action {
    color: #495057;
    padding: 1rem;
    font-weight: 600;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-submenu-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-menuitem-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem #bfd1f6;
  }
  .p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content {
    background: #e9ecef;
    border-color: 1px solid #dee2e6;
    color: #495057;
  }
  .p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight .p-panelmenu-header-content {
    background: #f8f9fa;
    border-color: #dee2e6;
    color: #495057;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 0;
  }
  .p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight:hover .p-panelmenu-header-content {
    border-color: #dee2e6;
    background: #e9ecef;
    color: #495057;
  }
  .p-panelmenu .p-panelmenu-content {
    padding: 0.25rem 0;
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-panelmenu .p-panelmenu-content .p-panelmenu-root-list {
    outline: 0 none;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content {
    color: #495057;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #495057;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #6c757d;
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content {
    color: #495057;
    background: #e9ecef;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #e9ecef;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #495057;
    background: #f8f9fa;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #495057;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #495057;
    background: #e9ecef;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }
  .p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-list) {
    padding: 0 0 0 1rem;
  }
  .p-panelmenu .p-panelmenu-panel {
    margin-bottom: 0;
  }
  .p-panelmenu .p-panelmenu-panel .p-panelmenu-header .p-panelmenu-header-content {
    border-radius: 0;
  }
  .p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
    border-radius: 0;
  }
  .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header .p-panelmenu-header-content {
    border-top: 0 none;
  }
  .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover .p-panelmenu-header-content {
    border-top: 0 none;
  }
  .p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header .p-panelmenu-header-content {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) .p-panelmenu-header-content {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-slidemenu {
    padding: 0.25rem 0;
    background: #ffffff;
    color: #495057;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    width: 12.5rem;
  }
  .p-slidemenu .p-slidemenu-root-list {
    outline: 0 none;
  }
  .p-slidemenu .p-submenu-list {
    outline: 0 none;
  }
  .p-slidemenu .p-menuitem > .p-menuitem-content {
    color: #495057;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-slidemenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #495057;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-slidemenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-slidemenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #6c757d;
    margin-right: 0.5rem;
  }
  .p-slidemenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-slidemenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #495057;
    background: #e9ecef;
  }
  .p-slidemenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-slidemenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-slidemenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-slidemenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #e9ecef;
  }
  .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #495057;
    background: #f8f9fa;
  }
  .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #495057;
  }
  .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #495057;
    background: #e9ecef;
  }
  .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
  .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-slidemenu.p-slidemenu-overlay {
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-slidemenu .p-slidemenu-list {
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-slidemenu .p-menuitem-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }
  .p-slidemenu .p-slidemenu-icon {
    font-size: 0.875rem;
  }
  .p-slidemenu .p-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
  .p-slidemenu .p-slidemenu-backward {
    padding: 0.75rem 1rem;
    color: #495057;
  }
  .p-slidemenu .p-slidemenu-backward:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem #bfd1f6;
  }
  .p-slidemenu .p-menuitem-badge {
    background: #C5357B;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 3px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .p-steps .p-steps-item .p-menuitem-link {
    background: transparent;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    background: #ffffff;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    color: #495057;
    border: 1px solid #c8c8c8;
    background: #ffffff;
    min-width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.143rem;
    z-index: 1;
    border-radius: 50%;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    margin-top: 0.5rem;
    color: #6c757d;
  }
  .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #C5357B;
    color: #ffffff;
  }
  .p-steps .p-steps-item.p-highlight .p-steps-title {
    font-weight: 600;
    color: #495057;
  }
  .p-steps .p-steps-item:before {
    content: " ";
    border-top: 1px solid #dee2e6;
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -1rem;
  }
  .p-tabmenu .p-tabmenu-nav {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-width: 0 0 2px 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-menuitem-badge {
    background: #C5357B;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 3px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    border: solid #dee2e6;
    border-width: 0 0 2px 0;
    border-color: transparent transparent #dee2e6 transparent;
    background: #ffffff;
    color: #6c757d;
    padding: 1rem;
    font-weight: 600;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    margin: 0 0 -2px 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: 0.5rem;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem #bfd1f6;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    background: #ffffff;
    border-color: #9ba2aa;
    color: #6c757d;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: #ffffff;
    border-color: #C5357B;
    color: #C5357B;
  }
  .p-tabmenu .p-tabmenu-left-icon {
    margin-right: 0.5rem;
  }
  .p-tabmenu .p-tabmenu-right-icon {
    margin-left: 0.5rem;
  }
  .p-tabmenu .p-tabmenu-nav-btn.p-link {
    background: #ffffff;
    color: #C5357B;
    width: 2.357rem;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border-radius: 0;
  }
  .p-tabmenu .p-tabmenu-nav-btn.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem #bfd1f6;
  }
  .p-tieredmenu {
    padding: 0.25rem 0;
    background: #ffffff;
    color: #495057;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    width: 12.5rem;
  }
  .p-tieredmenu.p-tieredmenu-overlay {
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-tieredmenu .p-tieredmenu-root-list {
    outline: 0 none;
  }
  .p-tieredmenu .p-submenu-list {
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
  .p-tieredmenu .p-menuitem > .p-menuitem-content {
    color: #495057;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #495057;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #6c757d;
    margin-right: 0.5rem;
  }
  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #495057;
    background: #e9ecef;
  }
  .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-tieredmenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #e9ecef;
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #495057;
    background: #f8f9fa;
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #495057;
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #495057;
    background: #e9ecef;
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-tieredmenu .p-menuitem-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }
  .p-tieredmenu .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-tieredmenu .p-submenu-icon.p-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
  .p-inline-message {
    padding: 0.5rem 0.5rem;
    margin: 0;
    border-radius: 3px;
  }
  .p-inline-message.p-inline-message-info {
    background: #039BE5;
    border: solid #027cb7;
    border-width: 1px;
    color: #ffffff;
  }
  .p-inline-message.p-inline-message-info .p-inline-message-icon {
    color: #ffffff;
  }
  .p-inline-message.p-inline-message-success {
    background: #43A047;
    border: 0 none;
    border-width: 1px;
    color: #ffffff;
  }
  .p-inline-message.p-inline-message-success .p-inline-message-icon {
    color: #ffffff;
  }
  .p-inline-message.p-inline-message-warn {
    background: #FFB300;
    border: 0 none;
    border-width: 1px;
    color: #495057;
  }
  .p-inline-message.p-inline-message-warn .p-inline-message-icon {
    color: #495057;
  }
  .p-inline-message.p-inline-message-error {
    background: #E53935;
    border: 0 none;
    border-width: 1px;
    color: #ffffff;
  }
  .p-inline-message.p-inline-message-error .p-inline-message-icon {
    color: #ffffff;
  }
  .p-inline-message .p-inline-message-icon {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
  .p-inline-message .p-icon {
    width: 1rem;
    height: 1rem;
  }
  .p-inline-message .p-inline-message-text {
    font-size: 1rem;
  }
  .p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
    margin-right: 0;
  }
  .p-message {
    margin: 1rem 0;
    border-radius: 3px;
  }
  .p-message .p-message-wrapper {
    padding: 1rem 1.5rem;
  }
  .p-message .p-message-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-message .p-message-close:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  .p-message .p-message-close:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-message.p-message-info {
    background: #039BE5;
    border: solid #027cb7;
    border-width: 0 0 0 4px;
    color: #ffffff;
  }
  .p-message.p-message-info .p-message-icon {
    color: #ffffff;
  }
  .p-message.p-message-info .p-message-close {
    color: #ffffff;
  }
  .p-message.p-message-success {
    background: #43A047;
    border: 0 none;
    border-width: 0 0 0 4px;
    color: #ffffff;
  }
  .p-message.p-message-success .p-message-icon {
    color: #ffffff;
  }
  .p-message.p-message-success .p-message-close {
    color: #ffffff;
  }
  .p-message.p-message-warn {
    background: #FFB300;
    border: 0 none;
    border-width: 0 0 0 4px;
    color: #495057;
  }
  .p-message.p-message-warn .p-message-icon {
    color: #495057;
  }
  .p-message.p-message-warn .p-message-close {
    color: #495057;
  }
  .p-message.p-message-error {
    background: #E53935;
    border: 0 none;
    border-width: 0 0 0 4px;
    color: #ffffff;
  }
  .p-message.p-message-error .p-message-icon {
    color: #ffffff;
  }
  .p-message.p-message-error .p-message-close {
    color: #ffffff;
  }
  .p-message .p-message-text {
    font-size: 1rem;
    font-weight: 500;
  }
  .p-message .p-message-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  .p-message .p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .p-message .p-message-summary {
    font-weight: 700;
  }
  .p-message .p-message-detail {
    margin-left: 0.5rem;
  }
  .p-toast {
    opacity: 0.9;
  }
  .p-toast .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.3);
    border-radius: 3px;
  }
  .p-toast .p-toast-message .p-toast-message-content {
    padding: 1rem;
    border-width: 0 0 0 4px;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin: 0 0 0 1rem;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
    font-size: 2rem;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-icon:not(.p-toast-icon-close-icon) {
    width: 2rem;
    height: 2rem;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
    font-weight: 700;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin: 0.5rem 0 0 0;
  }
  .p-toast .p-toast-message .p-toast-icon-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-toast .p-toast-message .p-toast-icon-close:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  .p-toast .p-toast-message .p-toast-icon-close:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-toast .p-toast-message.p-toast-message-info {
    background: #039BE5;
    border: solid #027cb7;
    border-width: 0 0 0 4px;
    color: #ffffff;
  }
  .p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
    color: #ffffff;
  }
  .p-toast .p-toast-message.p-toast-message-success {
    background: #43A047;
    border: 0 none;
    border-width: 0 0 0 4px;
    color: #ffffff;
  }
  .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
    color: #ffffff;
  }
  .p-toast .p-toast-message.p-toast-message-warn {
    background: #FFB300;
    border: 0 none;
    border-width: 0 0 0 4px;
    color: #495057;
  }
  .p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
    color: #495057;
  }
  .p-toast .p-toast-message.p-toast-message-error {
    background: #E53935;
    border: 0 none;
    border-width: 0 0 0 4px;
    color: #ffffff;
  }
  .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
    color: #ffffff;
  }
  .p-galleria .p-galleria-close {
    margin: 0.5rem;
    background: transparent;
    color: #ebedef;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
  }
  .p-galleria .p-galleria-close .p-galleria-close-icon {
    font-size: 2rem;
  }
  .p-galleria .p-galleria-close .p-icon-wrapper .p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-galleria .p-galleria-close:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #ebedef;
  }
  .p-galleria .p-galleria-item-nav {
    background: rgba(0, 0, 0, 0.2);
    color: #aeb6bf;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    margin: 0.5rem 0;
  }
  .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
  .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
    font-size: 2rem;
  }
  .p-galleria .p-galleria-item-nav .p-icon-wrapper .p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
    background: rgba(0, 0, 0, 0.3);
    color: #ebedef;
  }
  .p-galleria .p-galleria-caption {
    background: rgba(0, 0, 0, 0.5);
    color: #ebedef;
    padding: 1rem;
  }
  .p-galleria .p-galleria-indicators {
    padding: 1rem;
  }
  .p-galleria .p-galleria-indicators .p-galleria-indicator button {
    background-color: #e9ecef;
    width: 1rem;
    height: 1rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
  }
  .p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
    background: #dee2e6;
  }
  .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: #C5357B;
    color: #ffffff;
  }
  .p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
    margin-right: 0.5rem;
  }
  .p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
    margin-bottom: 0.5rem;
  }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background: rgba(0, 0, 0, 0.5);
  }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
    background: rgba(255, 255, 255, 0.4);
  }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
    background: rgba(255, 255, 255, 0.6);
  }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: #C5357B;
    color: #ffffff;
  }
  .p-galleria .p-galleria-thumbnail-container {
    background: rgba(0, 0, 0, 0.9);
    padding: 1rem 0.25rem;
  }
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
    margin: 0.5rem;
    background-color: transparent;
    color: #aeb6bf;
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
  }
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #aeb6bf;
  }
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-galleria-mask {
    --maskbg: rgba(0, 0, 0, 0.9);
  }
  .p-image-mask {
    --maskbg: rgba(0, 0, 0, 0.9);
  }
  .p-image-preview-indicator {
    background-color: transparent;
    color: #f8f9fa;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-image-preview-container:hover > .p-image-preview-indicator {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .p-image-toolbar {
    padding: 1rem;
  }
  .p-image-action.p-link {
    color: #f8f9fa;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-right: 0.5rem;
  }
  .p-image-action.p-link:last-child {
    margin-right: 0;
  }
  .p-image-action.p-link:hover {
    color: #f8f9fa;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .p-image-action.p-link i {
    font-size: 1.5rem;
  }
  .p-image-action.p-link .p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .p-avatar {
    background-color: #dee2e6;
    border-radius: 3px;
  }
  .p-avatar.p-avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
  }
  .p-avatar.p-avatar-lg .p-avatar-icon {
    font-size: 1.5rem;
  }
  .p-avatar.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
  }
  .p-avatar.p-avatar-xl .p-avatar-icon {
    font-size: 2rem;
  }
  .p-avatar-group .p-avatar {
    border: 2px solid #ffffff;
  }
  .p-badge {
    background: #C5357B;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
  }
  .p-badge.p-badge-success {
    background-color: #4CAF50;
    color: #ffffff;
  }
  .p-badge.p-badge-info {
    background-color: #03A9F4;
    color: #ffffff;
  }
  .p-badge.p-badge-warning {
    background-color: #FFC107;
    color: #495057;
  }
  .p-badge.p-badge-danger {
    background-color: #f44336;
    color: #ffffff;
  }
  .p-badge.p-badge-lg {
    font-size: 1.125rem;
    min-width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem;
  }
  .p-badge.p-badge-xl {
    font-size: 1.5rem;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem;
  }
  .p-chip {
    background-color: #dee2e6;
    color: #495057;
    border-radius: 16px;
    padding: 0 0.5rem;
  }
  .p-chip .p-chip-text {
    line-height: 1.5;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .p-chip .p-chip-icon {
    margin-right: 0.5rem;
  }
  .p-chip .pi-chip-remove-icon {
    margin-left: 0.5rem;
  }
  .p-chip img {
    width: 2rem;
    height: 2rem;
    margin-left: -0.5rem;
    margin-right: 0.5rem;
  }
  .p-chip .pi-chip-remove-icon {
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-chip .pi-chip-remove-icon:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-chip .pi-chip-remove-icon:focus {
    outline: 0 none;
  }
  .p-inplace .p-inplace-display {
    padding: 0.5rem 0.5rem;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-inplace .p-inplace-display:not(.p-disabled):hover {
    background: #e9ecef;
    color: #495057;
  }
  .p-inplace .p-inplace-display:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-metergroup {
    display: flex;
  }
  .p-metergroup-meters {
    display: flex;
  }
  .p-metergroup-vertical .p-metergroup-meters {
    flex-direction: column;
  }
  .p-metergroup-labels {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .p-metergroup-vertical .p-metergroup-labels {
    align-items: start;
  }
  .p-metergroup-labels-vertical {
    flex-direction: column;
  }
  .p-metergroup-label {
    display: inline-flex;
    align-items: center;
  }
  .p-metergroup-label-marker {
    display: inline-flex;
  }
  .p-metergroup {
    gap: 1rem;
  }
  .p-metergroup .p-metergroup-meters {
    background: #dee2e6;
    border-radius: 3px;
  }
  .p-metergroup .p-metergroup-meter {
    border: 0 none;
    background: #C5357B;
  }
  .p-metergroup .p-metergroup-labels .p-metergroup-label {
    gap: 0.5rem;
  }
  .p-metergroup .p-metergroup-labels .p-metergroup-label-marker {
    background: #C5357B;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
  }
  .p-metergroup .p-metergroup-labels .p-metergroup-label-icon {
    width: 1rem;
    height: 1rem;
  }
  .p-metergroup .p-metergroup-labels.p-metergroup-labels-vertical {
    gap: 0.5rem;
  }
  .p-metergroup .p-metergroup-labels.p-metergroup-labels-horizontal {
    gap: 1rem;
  }
  .p-metergroup.p-metergroup-horizontal {
    flex-direction: column;
  }
  .p-metergroup.p-metergroup-horizontal .p-metergroup-meters {
    height: 0.5rem;
  }
  .p-metergroup.p-metergroup-horizontal .p-metergroup-meter:first-of-type {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-metergroup.p-metergroup-horizontal .p-metergroup-meter:last-of-type {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-metergroup.p-metergroup-vertical {
    flex-direction: row;
  }
  .p-metergroup.p-metergroup-vertical .p-metergroup-meters {
    width: 0.5rem;
    height: 100%;
  }
  .p-metergroup.p-metergroup-vertical .p-metergroup-meter:first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .p-metergroup.p-metergroup-vertical .p-metergroup-meter:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-progressbar {
    border: 0 none;
    height: 1.5rem;
    background: #dee2e6;
    border-radius: 3px;
  }
  .p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: #C5357B;
  }
  .p-progressbar .p-progressbar-label {
    color: #ffffff;
    line-height: 1.5rem;
  }
  .p-scrolltop {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-scrolltop.p-link {
    background: rgba(0, 0, 0, 0.7);
  }
  .p-scrolltop.p-link:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  .p-scrolltop .p-scrolltop-icon {
    font-size: 1.5rem;
    color: #f8f9fa;
  }
  .p-scrolltop .p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .p-skeleton {
    background-color: #e9ecef;
    border-radius: 3px;
  }
  .p-skeleton:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
  }
  .p-tag {
    background: #C5357B;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 3px;
  }
  .p-tag.p-tag-success {
    background-color: #4CAF50;
    color: #ffffff;
  }
  .p-tag.p-tag-info {
    background-color: #03A9F4;
    color: #ffffff;
  }
  .p-tag.p-tag-warning {
    background-color: #FFC107;
    color: #495057;
  }
  .p-tag.p-tag-danger {
    background-color: #f44336;
    color: #ffffff;
  }
  .p-tag .p-tag-icon {
    margin-right: 0.25rem;
    font-size: 0.75rem;
  }
  .p-tag .p-icon {
    width: 0.75rem;
    height: 0.75rem;
  }
  .p-terminal {
    background: #ffffff;
    color: #495057;
    border: 1px solid #dee2e6;
    padding: 1rem;
  }
  .p-terminal .p-terminal-input {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
  }
}
/* Customizations to the designer theme should be defined here */

/*# sourceMappingURL=theme.css.map */
